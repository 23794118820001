import {useEffect} from 'react'
import "./Sitemap.css"
import { Link } from 'react-router-dom'
const Sitemap = () => {
    useEffect(() =>{
        document.title="Sitemap &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>
      <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center ">
            <h3 class="text-white animated slideInDown mb-4 mt-5">Sitemap</h3>
        </div>
    </div>
    <div class="container-xxl">
    <div class="row">    
        <div class="col">
            <ul>
                <li class="lii">Home</li>
                   <Link class="stynon" to="https://quaidsoft.com">Home</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/aboutus">About</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/team">Team</Link>
            </ul>
            <ul>
            <li class="lii">Services</li>
                 <Link class="stynon" to="https://quaidsoft.com/software-development">Software Development</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/web-development">Web Development</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/mobile-application-development">Mobile Application Development</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/project-consultancy">Real Estate Project Consultancy</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/domain-and-hosting">Domain And Hosting</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/digital_marketing">Digital Marketing Services</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/hardware-and-networking">Hardware And Networking</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/shoots-and-tv-commercials">TV SHOOTS & COMMERCIAL</Link><br/>
                   <Link class="stynon" to="https://quaidsoft.com/cctv-cameras">CCTV CAMERASL</Link>
                   </ul>

                   <ul>
            <li class="lii">Products</li>
            <Link  class="stynon"  to="https://quaidsoft.com/real-estate-crm-leads-management-system">Real Estate CRM-Leads- Management System</Link><br/>
                   <Link  class="stynon" to="https://quaidsoft.com/open-form-trading-with-qr-for-housing-building">Open Form Trading With (QR) For Housing/Building</Link><br/>
                   <Link class="stynon"  to="https://quaidsoft.com/housing-scheme-software-in-pakistan">Housing Scheme Software</Link><br/>
                   <Link class="stynon"  to="https://quaidsoft.com/building-sales-management-software-sales">Building Sales Management Software (Sales)</Link><br/>
                   <Link class="stynon"  to="https://quaidsoft.com/real-estate-software-in-pakistan">Housing Scheme Software</Link><br/>
                   <Link class="stynon"  to="https://quaidsoft.com/propertymanagementsystem">Property Management System</Link><br/>
                   <Link class="stynon"  to="https://quaidsoft.com/construction-management-software">Construction Management System</Link><br/>
                   <Link  class="stynon" to="https://quaidsoft.com/accountsmanagementsoftware">Accounting Management System</Link><br/>
                   <Link class="stynon"  to="https://quaidsoft.com/billing-management-software">Billing Management System</Link><br/>
                   <Link class="stynon"  to="https://quaidsoft.com/land-acquisition-management-system">Land Acquisition Management System</Link><br/>
                   <Link  class="stynon" to="https://quaidsoft.com/computerized-plot-balloting-for-housing-scheme">Computerized Plot Balloting For Housing Scheme</Link><br/>
                   <Link  class="stynon" to="https://quaidsoft.com/Marketing_Dealers_Commission_Management">Marketing/Dealers Commission Management (For Real Estate)</Link><br/>
                   <Link class="stynon"  to="https://quaidsoft.com/biometric-registration-software">Biometric Registration & Verification</Link><br/>
                   <Link  class="stynon" to="https://quaidsoft.com/brand-sms">Brand SMS</Link><br/>
                   <Link  class="stynon" to="https://quaidsoft.com/project-sales-recovery-follow-up">Project Sales Recovery Follow-Up</Link>
                   </ul>

        </div>
        <div class="col">
            <ul>
                <li class="lii">Careers</li>
                <Link class="stynon" to="https://quaidsoft.com/careers">Join Us</Link>
            </ul>
            <ul>
                <li class="lii">Portfolio</li>
                <Link class="stynon" to="https://quaidsoft.com/portfolio">Portfolio</Link>
            </ul>
            <ul>
                <li class="lii">Blog</li>
                <Link class="stynon" to="https://quaidsoft.com/blog">Blog</Link>
            </ul>
            <ul>
                <li class="lii">Contact Us</li>
                <Link class="stynon" to="https://quaidsoft.com/contactus">Contact Us</Link>
            </ul>
            <ul>
                <li class="lii">Applyjob</li>
                <Link class="stynon" target='_blank' to="https://quaidapps.com/apply-quaid-soft">Apply For Job</Link>
            </ul>
       
        </div>
       
    </div>

</div>

    </>
  )
}

export default Sitemap