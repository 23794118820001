import React from 'react'
import "./Demobar.css";
import { Link } from 'react-router-dom';
const Demobar2 = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
    <div className="p-4 cc demo1" >
    <div className="p-3">
    <h4 className='text-white'>Booking Domain and Hosting</h4>
    <span className='text-white main-style'>Ensure seamless operations with our Domain and Hosting services. We provide robust Services for infrastructure setup, maintenance
                  optimization. Trust us to keep your systems secure, efficient, and always connected.</span>
</div>
<Link to="/Booking-Hosting-Domin" onClick={scrollToTop} className="bttnn"> Booking for Domain and Hosting</Link>
</div>
    </>
  )
}

export default Demobar2