import {useEffect} from 'react'
import { Link } from 'react-router-dom';
import "./Aboutus.css";
import 'aos/dist/aos.css';
import AOS from 'aos';
const Aboutus = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
    <div class="container-xxl">
        <div class="row">
        <div class="d-ff" data-aos="zoom-in-down">
                    <div class="w-100" >
                    <h4 className='mb-4 textsix'>Why Only Quaid Soft as Trusted Software Development & IT Company?</h4>
                        <p class="abutextjus">Quaid Soft® is entirely a registered <Link className='textnon' to="/real-estate-software-in-pakistan" onClick={scrollToTop}><b>Real Estate Software Development</b></Link> firm from the Govt. of Pakistan as well
                             as under the firm registration law “Rule 9 (A) Section 58 (1) 1932." Quaid Soft was properly registered as a software
                              and IT company in 2015. Quaid Soft is purely a <Link className='textnon' to="/real-estate-software-in-pakistan" onClick={scrollToTop}><b>Real Estate Software</b></Link>  automation company. Where It’s Management 
                              (Mian Azhar and Muhammad Mustafa) having 15 years + experience in <Link className='textnon' to="/real-estate-software-in-pakistan" onClick={scrollToTop}><b>Real Estate Software</b></Link> automation Quaid Soft is serving approximately 600+
                              <Link className='textnon' to="/housing-scheme-software-in-pakistan" onClick={scrollToTop}><b> Real Estate Housing Software</b></Link>  and <Link className='textnon' to="/building-sales-management-software-sales" onClick={scrollToTop}><b>High-Rise Building Projects Software</b></Link> in all provinces of Pakistan. We are providing all kinds of software,
                              <Link className='textnon' to="/web-development" onClick={scrollToTop}><b>Web Develpoment</b></Link> and <Link className='textnon' to="/mobile-application-development" onClick={scrollToTop}><b>Mobile Applications Development</b></Link>, as well as hardware, network sales and maintenance services. Our aim is to provide our customers
                                 with the best-quality products and believe in after sale services in Pakistan and the Middle East. Quaid Soft is always dedicated 
                                 and a specialist in delivering the solution to builders and developers for their housing and commercial projects. Our service domain 
                                 sectors are real estate, builders, developers, construction companies, marketing companies, trading sectors, inventory sectors, (POS)
                                  point of sale, hotels, restaurants, pharmaceuticals, factories, marts, boutiques, production firms, and guest house setups.</p>
                        </div>                  
                   <div className='w-80'  >
                     <img class="aboutimg" src={process.env.PUBLIC_URL +'/Images/Aboutus/about-jif.gif'} alt="quaidsoft-about us"/>
                       </div>                          
                       </div>
                       </div>
                       </div>
               
    </>
  );
}

export default Aboutus;