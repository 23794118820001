import {useEffect} from 'react'
import "./biometricregistrationsoftware.css"
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
import { Link } from 'react-router-dom'
const Biometricregistrationsoftware = () => {
    useEffect(() =>{
        document.title="Biometric Verification System Providers In Pakistan | Biometric Software Company In Pakistan | Biometric Registration Software &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
   <>

<div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Biometric Registration & Verification</h3>
        </div>
    </div>
    <div className="container-xxl py-6 p-4 courses" id="abouts">
                <div className="row">
                <div className="wow  bounceInUp mon1"data-wow-delay="0.2s">
                    <div className="w-100 p-2">
                        <h3>Biometric Registration & Verification</h3>
                                                <p className="mb-2 acctextjus" >Biometric verification is any means by which a person can be uniquely identified by evaluating one or more distinguishing biological traits.</p>
                                                <p className="mb-2  acctextjus" >Significance of <strong className="text-black"> Biometric Registration & Verification </strong> of your valued customers is increasing day to day. By using Biometric every single customer biometric will be scan & store digitally against his each file/plot. So, when a customer needs to transfer a file/plot to another person then his fingerprint will be verified from the biometric system. If finger print will be verified then the system will allow resuming of transfer procedure. In case of not verifying, the system will not be allowed to continue. Biometric system is fully secure & purely an AddOn Module which will be integrated with the Real-Sales Management System.  
                                                   </p>   

                                                   
                                                   <p className="mb-3 acctextjus" >Quaid Soft also provides <Link className="Link1" to="/accountsmanagementsoftware" > <b>Accounting software</b> </Link>,<Link className="Link1" to="/billing-management-software" > <b>Billing management system</b> </Link> , <Link className="Link1" to="/brand-sms" > <b>Brand Sms Software</b> </Link>,<Link className="Link1" to="/housing-scheme-software-in-pakistan" > <b>Inventory Management Software</b> </Link> ,<Link className="Link1" to="/construction-management-software" > <b>Construction Management Software</b> </Link> and Many others.</p>

                                                    <p className="mb-3  acctextjus" >Quaid soft gives 24/7 live support help our clients to achieve their milestones without any difficulty. Stay 24/7 connected with software through desktop. So whether you want to handle mid Real Estate operations or complex operations such as housing property operations, let our<strong className="text-black"> Biometric Registration & Verification software </strong>automate your requirements and help you in your success mission in a managed way.</p>
                                                </div>   
                         
                            
                            </div>

<div className="p-3 demo1">
    <div className="p-2">
    <h4 className="text-white">Biometric Registration & Verification Details </h4>
</div>
</div>

<div className="container-xxl p-3" id="myGroup">
    <button className="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" href="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i className="bi bi-plus-circle phh1" ></i>Biometric Registration</button><br/>
 
          <div className=" p-3 collapse text-black" id="lead" data-bs-parent="#myGroup">
           
           
        </div> 


        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i className="bi bi-plus-circle phh1" ></i>Biometric Registration against each File/Plot</button> 
        <div className="p-3  collapse text-black" id="FollowUpLeads" data-bs-parent="#myGroup">
        
           
           </div>

        <button className="btttt bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i className="bi bi-plus-circle phh1" ></i>Digital Store Biometric in Database</button> 
        <div className="p-3  collapse text-black"  id="LeadsStatus" data-bs-parent="#myGroup">
        
           
        </div>

        <button className="  btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i className="bi bi-plus-circle phh1" ></i>Integration of Biometric on File/Plot Transfer Module</button> 
        <div className="p-3  collapse text-black" id="ForwardLeads" data-bs-parent="#myGroup">
        
        </div>
 
        

     
        <button className="btttt  bounceInUp wow" data-wow-delay="0.6s" data-bs-toggle="collapse" href="#LeadsAnalysis" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsAnalysis" > <i className="bi bi-plus-circle phh1" ></i>Verification of Customer Biometric</button> 
        <div className="p-3  collapse text-black"  id="LeadsAnalysis" data-bs-parent="#myGroup">
        
           
        </div>

      
        <button className="btttt  bounceInUp wow" data-wow-delay="0.7s" data-bs-toggle="collapse" href="#LeadsReporting" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsReporting" > <i className="bi bi-plus-circle phh1" ></i>Missing Biometric Customer Reports</button> 
        <div className="p-3  collapse text-black" id="LeadsReporting" data-bs-parent="#myGroup">
        
           
  
        </div>

 
        <button className="btttt  bounceInUp wow" data-wow-delay="0.8s" data-bs-toggle="collapse" href="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i className="bi bi-plus-circle phh1" ></i>Biometric Complete Customers Reports</button> 
        <div className="p-3  collapse text-black"   id="LeadsImportTrough" data-bs-parent="#myGroup">
            
        </div>   
        </div>
        </div>
        <Demo/>
        </div>
 

   </>
  )
}

export default Biometricregistrationsoftware