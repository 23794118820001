import {useEffect} from 'react'
import "./brandsms.css"
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
import { Link } from 'react-router-dom'
const Brandsms = () => {
    useEffect(() =>{
        document.title="SMS software, marketing software, masking, corporate service provider, brandsms integration, twitter brand sms &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>
    <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Brand SMS</h3>
        </div>
    </div>
    
    <div class="container-xxl py-6 p-4 courses" id="abouts">
                <div class="row">
                <div class="wow  bounceInUp mon1"data-wow-delay="0.2s">
                    <div class="w-100 p-2">
                        <h3>Brand SMS</h3>
                                                <p class="mb-2 acctextjus" >You want your customers to trust your words? Now, send SMS together with your Company/ name. Now Brand SMS are delivered from your name rather than an unknown number or code. Hence, creates content authenticity of the corporate message.
                                                   </p>   
                                                   <p class="mb-2 acctextjus" >Now send Brand SMS with your company name instead of any unknown number. This enables the reader to trust the content of your SMS. In other words, we call this “SMS on letterhead”. The response rate of branded SMS is way far better than SMS from ordinary numbers. Since this type of SMS is generated with SMS gateway and direct connectivity with Network the speed is generally very good i.e. 20 – 30 SMS per second compared with 8 SMS per minute from normal sim. Branded SMS generally better suits commercial organization where reliability of the content really matters. Brand SMS Software particularly designed & developed by observing the day to day needs of fast interaction with your valued Clients. Where you can send welcome, Payment Reminder, Acknowledgement, Recovery & Many Other Types of SMS with your Brand Name legally to your valued customers.
                                                </p>   
                                                   
                                                <p className="mb-3 acctextjus" >Quaid Soft also provides <Link className="Link1" to="/accountsmanagementsoftware" > <b>Accounting software</b> </Link>,<Link className="Link1" to="/billing-management-software" > <b>Billing management system</b> </Link> , <Link className="Link1" to="/brand-sms" > <b>Brand Sms Software</b> </Link>,<Link className="Link1" to="/housing-scheme-software-in-pakistan" > <b>Inventory Management Software</b> </Link> ,<Link className="Link1" to="/construction-management-software" > <b>Construction Management Software</b> </Link> and Many others.</p>

                                                    <p class="mb-3 acctextjus " >Quaid soft gives 24/7 live support help our clients to achieve their milestones without any difficulty. Stay 24/7 connected with software through desktop. So whether you want to handle mid Real Estate operations or complex operations such as housing property operations, let our<strong > Brand SMS software </strong>automate your requirements and help you in your success mission in a managed way.</p>
                                                </div>   
                    </div>


<div class="p-3 demo1" >
    <div class="p-2">
    <h4 class="text-white">Brand SMS includes:</h4>
</div>
</div>

<div class="container-xxl p-3" id="myGroup">
    <button class="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" href="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i class="bi bi-plus-circle phh1"></i>Secure “Sign in” through Login ID & Password</button><br/>
 
          <div class=" p-3 collapse text-black" id="lead" data-bs-parent="#myGroup">
            
           
           
        </div> 


        <button class="btttt  bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i class="bi bi-plus-circle phh1"></i>Integrated with Real Soft (Sales System as Add-on Module)</button> 
        <div class="p-3  collapse text-black" id="FollowUpLeads" data-bs-parent="#myGroup">
        
           
           </div>

        <button class="btttt bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i class="bi bi-plus-circle phh1"></i>Welcome SMS On Fresh Booking</button> 
        <div class="p-3  collapse text-black" id="LeadsStatus" data-bs-parent="#myGroup">
        
           
        </div>

        <button class="  btttt  bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i class="bi bi-plus-circle phh1"></i>Auto SMS Generate on any Payment Received</button> 
        <div class="p-3  collapse text-black"   id="ForwardLeads" data-bs-parent="#myGroup">
        
        </div>
 
     

     
        <button class="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#LeadsAnalysis" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsAnalysis" > <i class="bi bi-plus-circle phh1"></i>Data Filtration for SMS Sending</button> 
        <div class="p-3  collapse text-black"  id="LeadsAnalysis" data-bs-parent="#myGroup">
            <li class="fontsm">Default Customers</li>
            <li class="fontsm">Criteria Base Filtration of Customers for SMS</li>
            <li class="fontsm">Particular Installment Missed</li>
            <li class="fontsm">Cancel Files/Plot</li>
            <li class="fontsm">Regular Customer</li>
            <li class="fontsm">Many Other Criteria For Data Filter & SMS Sending</li>
           
        </div>

      
        <button class="btttt  bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsReporting" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsReporting" > <i class="bi bi-plus-circle phh1"></i>SMS Hold / Release Facility</button> 
        <div class="p-3  collapse text-black"  id="LeadsReporting" data-bs-parent="#myGroup">
        
            <li class="fontsm">Criteria Base Filtration of Customers for SMS</li>
            <li class="fontsm">Particular Installment Missed</li>
            <li class="fontsm">Cancel Files/Plot</li>
            <li class="fontsm">Regular Customer</li>
            <li class="fontsm">Many Other Criteria For Data Filter & SMS Sending</li>
  
        </div>

 
        <button class="btttt  bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i class="bi bi-plus-circle phh1"></i>SMS Subscription / Unsubscribe</button> 
        <div class="p-3  collapse text-black"  id="LeadsImportTrough" data-bs-parent="#myGroup">
            
            <li class="fontsm">Criteria Base Filtration of Customers for SMS</li>
            <li class="fontsm">Particular Installment Missed</li>
            <li class="fontsm">Cancel Files/Plot</li>
            <li class="fontsm">Regular Customer</li>
            <li class="fontsm">Many Other Criteria For Data Filter & SMS Sending</li>
        </div>
 
        <button class="btttt  bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#Facebookintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Facebookintegration" > <i class="bi bi-plus-circle phh1"></i>SMS complete Log Record</button> 
        <div class="p-3  collapse text-black"  id="Facebookintegration" data-bs-parent="#myGroup">
        
           
        </div>   
        </div>
        </div>
        <Demo/>
        </div>
        
    </>
  )
}

export default Brandsms