import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import "./Navbar.css";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
const Navbar1 = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNavLinkClick = () => {
    setNavbarOpen(false);
  };
  const navfun = () =>{
    handleNavLinkClick();
    scrollToTop();

  }

  return (
   <>

   
<nav className={`navbar navbar-expand-md navbar-light fixed-top bg-light ${navbarOpen ? 'open' : ''}`} aria-label="Fourth navbar example">
        <div className="container-xxl">
          <NavLink className="navbar-brand d-flex" onClick={navfun} to="/">
            <img className='logocss' src={process.env.PUBLIC_URL + '/Images/Websiteimages/cropped-property-management-software-Pakistan-100x99.png'} alt="Pakistan Top Real Estate Software Company, Top Ten Real Estate Software, Developers Management System, Building Sales Management System, Society Billing System, Maintenance Management System, Construction Management System, Open Form Trading System, Open Form QR Code Printing, QR Code Printing on Open Form, Real Estate File Trading System, Islamabad Housing Scheme Software, Real Estate Software Islamabad, Real Estate Software Faisalabad, Real Estate Software" />
          <h2 className='mt-2 quadilog2'>QUAID</h2><h2 className='mt-2 quadilog'>SOFT</h2>
          </NavLink>
          <button className="navbar-toggler" type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`} id="navbarsExample04">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
          <li className="nav-item">
            <NavLink className="nav-link" aria-current="page" onClick={navfun} to="/">Home</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" onClick={navfun} to="/aboutus">About</NavLink>
          </li>
          <NavDropdown title="Services" id="basic-nav-dropdown">
            <NavDropdown.Item as={NavLink} to="/software-develpoment" onClick={navfun}>Software Development</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/web-development" onClick={navfun}>Website Development</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/mobile-application-development" onClick={navfun}>Mobile App Development</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/project-consultancy" onClick={navfun}>Project Consultancy</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/domain-and-hosting" onClick={navfun}>Domain & Hosting</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Digital_Marketing" onClick={navfun}>Digital Marketing</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/hardware-and-networking" onClick={navfun}>Hardware & Networking</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/shoots-and-tv-commercials" onClick={navfun}>Shoots & Tv Commercial</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/cctv-cameras" onClick={navfun}>CCTV Cameras</NavDropdown.Item>

          </NavDropdown>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/product">Products</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/portfolio">Portfolio</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/team">Team</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/careers">Careers</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/blog">Blog</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/gallery">Gallery</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/contactus">Contact Us</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/support">Support</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/sitemap"><i class="bi bi-diagram-3-fill"></i></NavLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
   </>
  );
} 
export default Navbar1;
