import {useEffect} from 'react';
import "./Protfolio.css";

const Protfolio = () => {
  const onButtonClick = () => {
    const pdfUrl = "Quaidsoftprofile.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "QuaidSoft.com.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
  useEffect(() =>{
    document.title="Portfolio &#8211; Quaid Soft | ERP Software Company In Pakistan";
},[]);
  return (
    <>
      <div className="page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h3 className="text-white animated slideInDown mb-4 mt-5">Protfolio</h3>
        </div>
      </div>
      <div className="container-xxl courses p-4">
      <div className='row'>
      <center>
                <h1>Welcome to Quaid Soft</h1>
                <div className='w-100 '>
                  <img className='imgaa' src={process.env.PUBLIC_URL + '/Images/Aboutus/adobe-24943_1280.png'} alt=''/>

                </div>
                <button className='bttnn' onClick={onButtonClick}>
                Download Quaid Soft Protfolio
                </button>
            </center>


    <div>

   </div>
    <div>


    </div>
      </div>
      </div>
    </>
  );
};

export default Protfolio;
