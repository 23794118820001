import React from 'react'
import "./Applyjob.css"
import { Link } from 'react-router-dom'
const Applyjob = () => {
  const handleApplyJob = () => {
    const email = 'info@quaidsoft.com';
    const subject = encodeURIComponent('Job Application');
    const body = encodeURIComponent(`Dear Hiring Manager,

I am writing to express my interest in the [Job Title] position advertised on your website. Please find attached my resume for your consideration.

Thank you for your time and consideration.

Sincerely,
[Your Name]`);

    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };
  return (
    <>
     <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Apply Now</h3>
        </div>
    </div>
    <center>
                <h1> Join Quaid Soft</h1>
                <div className='w-100 '>
                  <h2>Send You CV from this Email</h2>
                  <Link className='lling' onClick={handleApplyJob}>info@quaidsoft.com</Link>
                 

                </div>
               
            </center>
    </>
  )
}

export default Applyjob