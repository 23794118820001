import {useEffect} from 'react'
import { Link } from 'react-router-dom'
import "./accountsmanagementsoftware.css"
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
const Accountsmanagementsoftware = () => {
    useEffect(() =>{
        document.title="accounts management system, system software, accounting managing transactions with account sales, real &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>
   <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Accounting Management System</h3>
        </div>
    </div>

    <div className="container-xxl py-6 p-4 courses">
                <div className="row">
                <div className="wow  bounceInUp mon1"data-wow-delay="0.2s">
                    <div className="w-100 p-2">
                        <h3>Accounting Management System</h3>
                                                <p className="mb-2 acctextjus " >Consists of your Accounting manual, associated accounting policies and procedures, and accounting documents. The Accounts Management System uses standard forms and provides control and accountability over these forms. The Accounts Management System is easy to use, a complete software solution which automates and streamlines your entire financial transactions that enable you to compete in a vibrant environment. In every business having a clear, comprehensive and transparent record keeping system is essential, and Accounting is the most basic framework of any business. The Accounts Management System provides an organization, great financial intelligibility thus empowering company wide financial control through improved financial management and forecasting. It offers efficient solutions to maintain complete Financial Control from creating and managing transactions to consolidating and reporting results. As Accounting system is a fully integrated financial solution that ensures access to accurate financial information for the effective monitoring and proper management of financial activities. All transactions recorded or posted into the Accounts Management System should be properly authorized and accurately represent the activity being documented. Both the timing and amount of the transaction should be in accordance with company accounting policies.</p>                                               
                                                <p className="mb-3 acctextjus" >Quaid Soft also provides <Link className="Link1" to="/accountsmanagementsoftware"> <b>Accounting software</b> </Link>,<Link className="Link1" to="/billing-management-software"> <b>Billing management system</b> </Link> , <Link className="Link1" to="/brand-sms"> <b>Brand Sms Software</b> </Link>,<Link className="Link1" to="/housing-scheme-software-in-pakistan" > <b>Inventory Management Software</b> </Link> ,<Link className="Link1" to="/construction-management-software" > <b>Construction Management Software</b> </Link> and Many others.</p>
                                                    <p className="mb-3 acctextjus " >Quaid Soft gives 24/7 live support help our clients to achieve their milestones without any difficulty. Stay 24/7 connected with software through desktop. So whether you want to handle mid Real Estate operations or complex operations such as housing property operations, let our<strong className="text-black"> Accounts Management System software </strong>automate your requirements and help you in your success mission in a managed way.</p>
                                                </div>   
                         
                            
                            </div>


<div className="p-3 demo1">
    <div className="p-2">
    <h4 className="text-white">Housing Society Software includes</h4>
</div>
</div>

<div className="container-xxl p-3" id="myGroup">
    <button className="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" to="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i className="fa fa-check phh1" ></i>Chart of Accounts (Customizable)</button><br/>
        <button className="btttt  bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" to="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i className="fa fa-check phh1" ></i>Classify Multi Vouchers Type</button> 
     

        <button className="btttt bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" to="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i className="fa fa-check phh1" ></i>Classify Financial Year (Opening & Closing)</button> 
    

        <button className="  btttt  bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" to="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i className="fa fa-check phh1" ></i>Transfer your Closing Balance as Opening in Software</button> 
   
 
        <button className=" btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" to="#Dashboard" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Dashboard" > <i className="fa fa-check phh1" ></i>System Generated Voucher No. provision for Voucher Entry</button> 
    


     
        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" to="#LeadsAnalysis" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsAnalysis" > <i className="fa fa-check phh1" ></i>Manual Voucher No. provision for Voucher Entry</button> 
     

      
        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" to="#LeadsReporting" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsReporting" > <i className="fa fa-check phh1" ></i>(G-L) General Ledger Account Open</button> 
    

 
        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" to="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i className="fa fa-check phh1" ></i>Double Entry Voucher System (Debit & Credit View)</button> 
     
        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" to="#Facebookintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Facebookintegration" > <i className="fa fa-check phh1" ></i>Voucher Deletion & Amendment (Available with User’s Rights check)</button> 
   
  
        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" to="#SMSintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="SMSintegration" > <i className="fa fa-check phh1" ></i>Voucher Posting / Finalization Desk</button> 
   

        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" to="#drp1" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp1" > <i className="fa fa-check phh1" ></i>Vouchers Inquiries with Un-Editable View</button> 
 
        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#drp2" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp2" > <i className="fa fa-check phh1" ></i>Well Defined Search Algorithm to Search Voucher</button> 
      

        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#drp3" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp3" > <i className="bi bi-plus-circle phh1" ></i>Accounts Reports Highlight</button> 
        <div className="p-3  collapse text-black"  id="drp3" data-bs-parent="#myGroup">
        
            <li className="fontsm" >(MIS) MIS  System, Fully parameterized</li>
            <li className="fontsm" >Balance Sheet / Profit Loss Statement</li>
            <li className="fontsm" >Trial Balance , Accounts Ledger</li>
            <li className="fontsm" >Day Book, Cash Book, Accounts Aging</li>
            
        </div>  
        </div> 
        </div> 
        <Demo/>
        </div> 
    </>
  )
}

export default Accountsmanagementsoftware