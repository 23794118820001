import {useEffect} from 'react'
import { Link } from 'react-router-dom'
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
import "./buildingsalesmanagementsoftwaresales.css"
const Buildingsalesmanagementsoftwaresales = () => {
    useEffect(() =>{
        document.title="Building Sales Management Software (Sales) &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>
    <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Building Sales Management Software (Sales)</h3>
        </div>
    </div>
    <div className="container-xxl  py-6 p-4 courses" id="abouts">
                <div className="row">
                <div className="wow  bounceInUp mon1"data-wow-delay="0.2s">
                    <div className="w-100 p-2">
                        <h4>Building Sales Management Software (Sales)</h4>
                                                <p className="mb-2 acctextjus  " >is an automating software for the sales process to organize their entire customer accounts. Quaidsoft offers the best possible solution to Real Estate/Property Management Software in Pakistan. Quaid Soft provides Services all over Pakistan including major cities Lahore,Islamabad.</p>
                                                <p className="mb-2 acctextjus" >Revenue increase and decrease can be affected by this system too. Also, this system can provide statistical analysis which shows how each sales staff  does during the sales process and sales cycles. In addition,<strong className="text-black"> Building Sales Management Software (Sales) </strong> is one of the sales and operational CRM technologies to increase customers’ satisfaction for the sales department.
                                                   </p>   
                                                   <strong className="mb-2 text-black" > The Property  Management System is for both Housing and Commercial Projects.</strong>

                                                   <p className="mb-2 acctextjus " > <strong className="text-black">Our Property Management System</strong> in  Pakistan Real Estate industry  is designed to also optimize sales management processes by improving customer service, evaluating sales activities, capturing trends, and performing an advanced statistical analysis.<strong className="color-black">Building Sales Management Software (Sales)</strong> provides comprehensive reports for managing the Real Estate agency performance and  efficiency, and enables the management for better decision-making.</p>
                                                   
                                                   <p className="mb-3 acctextjus" >Quaid Soft also provides <Link className="Link1" to="/accountsmanagementsoftware" > <b>Accounting software</b> </Link>,<Link className="Link1" to="/billing-management-software" > <b>Billing management system</b> </Link> , <Link className="Link1" to="/brand-sms" > <b>Brand Sms Software</b> </Link>,<Link className="Link1" to="/housing-scheme-software-in-pakistan" > <b>Inventory Management Software</b> </Link> ,<Link className="Link1" to="/construction-management-software" > <b>Construction Management Software</b> </Link> and Many others.</p>

                                                    <p className="mb-3 acctextjus " >Quaid Soft gives 24/7 live support help our clients to achieve their milestones without any difficulty. Stay 24/7 connected with software through desktop. So whether you want to handle mid Real Estate operations or complex operations such as housing property operations, let our<strong className="color-black"> Real Estate Software </strong>automate your requirements and help you in your success mission in a managed way.</p>
                                                </div>   
                         
                            
                            </div>
<div className="p-3 demo1">
    <div className="p-2">
    <h4 className="color-black">Building Sales Management Software (Sales) includes:</h4>
</div>
</div>

<div className="container-xxl p-3" id="myGroup">
    <button className="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" href="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i className="bi bi-plus-circle"  ></i>Digitization of Map / Floor Plan</button><br/>
 
          <div className=" p-3 collapse text-black" id="lead" data-bs-parent="#myGroup">
            <li className="fontsm">Floor Information</li>
            <li className="fontsm">Shop/Apartment Information</li>
            <li className="fontsm">Shop/Apartment Type/Nature & Category</li>
            <li className="fontsm">Shop/Apartment Status (Booked, Available/Reserve)</li>
            <li className="fontsm">Inquiry of (Available, Booked, Token & Reserved) Shop/Apartment</li>
           
        </div> 


        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i className="bi bi-plus-circle"  ></i>Client / File Registration Process</button> 
        <div className="p-3  collapse color-black" id="FollowUpLeads"  data-bs-parent="#myGroup">
        
            <li className="fontsm">Size Wise File Allocate to Client</li>

            <li className="fontsm"><b>Personal Information of Client</b></li>
           
            <ul className="fontsm">
                <li>Name, Address, Contacts, (Scan Picture Attachment)</li>
                <li> Property Allocation at Booking Time (If Required)</li>
                </ul>
            <li className="fontsm"><b>Booking’s Information of Client </b></li>
            <ul className="fontsm">
            <li>Total Price, Discount, Down Payment</li>
            <li>Installment & Other Payments Plan</li>
            </ul>

            <li className="fontsm"><b>Payment Plan</b></li>
            <ul className="fontsm">
                <li>Facility of Fixed and Customized Plan</li>
                <li>Discount against Installment / Payments</li>
                </ul>
           </div>

        <button className="btttt bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i className="bi bi-plus-circle"  ></i>File Information</button> 
        <div className="p-3  collapse text-black" id="LeadsStatus" data-bs-parent="#myGroup">
        
            <li className="fontsm"><b>Nominee Information</b></li>
            <ul className="fontsm">
                <li>ID Card, Name, Relationship, Address</li>
                </ul>
                <li className="fontsm"><b>Misc File Information</b></li>
            <ul className="fontsm">
                <li>File Form No., Allotment No., Allotment Date</li>
                <li>Mail / Contact Subscription / Un-Subscriptions</li>
                </ul>
        </div>

        <button className="  btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i className="bi bi-plus-circle"  ></i>Property Allocation</button> 
        <div className="p-3  collapse text-black" id="ForwardLeads" data-bs-parent="#myGroup">
        
          <li className="fontsm">Allotment of Particular Plot/shop/Apartment</li>
          <li className="fontsm">Allocation Date, Allocation Authority</li>
        </div>
 
        <button className=" btttt  bounceInUp wow" data-wow-delay="0.5s" data-bs-toggle="collapse" href="#Dashboard" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Dashboard" > <i className="bi bi-plus-circle"  ></i>Property Change</button> 
        <div className="p-3  collapse color-black"   id="Dashboard" data-bs-parent="#myGroup">
        
           <li className="fontsm">Extra- Facility to Change the Property Information</li>
          <li className="fontsm">Property Change History</li>
          <li className="fontsm">OLD Block/Floor No. Shop/Apartment No</li>
          <li className="fontsm">Shop/Apartment Status Booked </li>
          
          <li className="fontsm">Change Date, Change Officer</li> 
        </div>


     
        <button className="btttt  bounceInUp wow" data-wow-delay="0.6s" data-bs-toggle="collapse" href="#LeadsAnalysis" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsAnalysis" > <i className="bi bi-plus-circle"  ></i>Receipt / Invoice (Against Installments/Payment)</button> 
        <div className="p-3  collapse text-black" id="LeadsAnalysis" data-bs-parent="#myGroup">
        
            <li className="fontsm">Cash, Cheque, Receiving Date, Receiving Amount</li>
            <li className="fontsm">Cheque Complete Details (Drawee and Deposit Information)</li>
            <li className="fontsm">Auto Settlement of Received Amount in Due Payments</li>
            <li className="fontsm">Printing of Receipt/Invoice Copies </li>
        </div>

      
        <button className="btttt  bounceInUp wow" data-wow-delay="0.7s" data-bs-toggle="collapse" href="#LeadsReporting" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsReporting" > <i className="bi bi-plus-circle"  ></i>Refund / Merge / Purchase Process</button> 
        <div className="p-3  collapse text-black" id="LeadsReporting" data-bs-parent="#myGroup">
        
            <li className="fontsm">Refund Settlement Making</li>
            <li className="fontsm">Total Received, Non-Refundable, Deduction</li>
            <li className="fontsm">Net Refund Amount, Profit Calculation on Refund</li>
            <li className="fontsm">Refund History (Complete)</li>
  
        </div>

 
        <button className="btttt  bounceInUp wow" data-wow-delay="0.8s" data-bs-toggle="collapse" href="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i className="bi bi-plus-circle"  ></i>Transfer of Property</button> 
        <div className="p-3  collapse text-black" id="LeadsImportTrough" data-bs-parent="#myGroup">
            
            <li className="fontsm">Transfer Draft / Application Process</li>
            <li className="fontsm">Buyer Information update in System</li>
            <li className="fontsm"><b>Printing of Transfer Documentation</b></li>
            <ul className="fontsm">
                <li> (Allotment / Provisional Allotment Letter)</li>
                <li> NOC (No Objection Certificate)</li>
                <li> Undertaking Letter</li>
                <li> Client update Ledger</li>
                <li> Transfer History</li>
            </ul>
        </div>
 
        <button className="btttt  bounceInUp wow" data-wow-delay="0.9s" data-bs-toggle="collapse" href="#Facebookintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Facebookintegration" > <i className="bi bi-plus-circle"  ></i>File / Registration Cancellation</button> 
        <div className="p-3  collapse text-black" id="Facebookintegration" data-bs-parent="#myGroup">
        
            <li className="fontsm">Cancellation due to Non Payments for a long time</li>
            <li className="fontsm">Cancellation Date, Cancellation Reason, Cancellation Officer</li>
            
            

        </div>
  
        <button className="btttt  bounceInUp wow" data-wow-delay="1s" data-bs-toggle="collapse" href="#SMSintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="SMSintegration" > <i className="bi bi-plus-circle"  ></i>File / Registration Re-Activation</button> 
        <div className="p-3  collapse text-black" id="SMSintegration" data-bs-parent="#myGroup">
        
            <li className="fontsm">Activation if Client wants to resume his File / Property</li>
            <li className="fontsm">Activation date, Activation Remarks, Activation Officer</li>


        </div>  

        <button className="btttt  bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#drp1" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp1" > <i className="bi bi-plus-circle"  ></i>Inquiries</button> 
        <div className="p-3  collapse text-black" id="drp1" data-bs-parent="#myGroup">
        
            <li className="fontsm">Inquiry of (Available, Booked, Token & Reserved) Plot/Shop/Apartment</li>
            <li className="fontsm">Payment Receipts Inquiry</li>
            <li className="fontsm">Client File / Registration Complete Inquiry</li>
        </div>  
       

        <button className="btttt  bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#drp3" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp3" > <i className="bi bi-plus-circle"  ></i>Member Forms</button> 
        <div className="p-3  collapse text-black" id="drp3" data-bs-parent="#myGroup">
        
            <li className="fontsm">Client Ledger / Form (Different Format)</li>
            
        </div>  
        <button className="btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#drp4" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp4" > <i className="bi bi-plus-circle"  ></i>Invoice Printing (Different Format)
        </button> 
    
       <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#drp2" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp2" > <i className="bi bi-plus-circle"  ></i> Reports</button> 
        <div className="p-3  collapse text-black" id="drp2" data-bs-parent="#myGroup">
            <li className="fontsm">Booking Reports (Date Wise)</li>
            <li className="fontsm"> Booking Report (Size Wise)</li>
            <li className="fontsm"> Property Reports</li>
            <li className="fontsm"> Refund Reports</li>
            <li className="fontsm"> Transfer Reports</li>
            <li className="fontsm"> Cancellation / Re-Activation Reports</li>
            <li className="fontsm"> Other Many Customized Reports on Demand Development</li>
            
            </div> 
            </div> 
        </div> 
        <Demo/>
        </div> 

    </>
  )
}

export default Buildingsalesmanagementsoftwaresales