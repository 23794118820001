import {useEffect} from 'react'
import "./Product.css";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Link } from 'react-router-dom';
const Products = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  return (
    <>
    <div className="container-xxl courses ">
        <div className="row p-4 text-center  ">
            <div  className="mob1">
                <div data-wow-delay="0.1s" data-aos="zoom-in"  className="text-center bounceInDown wow  mx-auto maindive1 text-center text-center">
             <Link  onClick={scrollToTop} to="/real-estate-crm-leads-management-system"> <img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Real-Estate-CRM-Leads-Management-System.png'} alt="Real Estate CRM-Leads -Management-System"/></Link>
                <span>Real Estate CRM-Leads Management System</span>
            </div>
            
            <div data-wow-delay="0.2s" data-aos="zoom-in" className="bounceInDown wow maindive1 text-center mx-auto">
               <Link  onClick={scrollToTop} to="/open-form-trading-with-qr-for-housing-building"><img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Open-Form-Trading-For-Housing.png'} alt="Open-Form-Trading-For-Housing"/></Link>
                <span>Open Form Trading For Housing</span>
            </div>
            <div  data-wow-delay="0.3s" data-aos="zoom-in" className="bounceInDown wow maindive1 text-center mx-auto">
               <Link  onClick={scrollToTop} to="/housing-scheme-software-in-pakistan"><img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Housing-Scheme-Software-Sales.png'} alt="Housing-Scheme-Software-Sales"/></Link>
                <span>Housing Scheme Software (Sales)</span>
            </div>
            <div  data-wow-delay="0.4s" data-aos="zoom-in" className="bounceInDown wow maindive1 text-center mx-auto">
              <Link  onClick={scrollToTop} to="/building-sales-management-software-sales"><img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Building-Management-Software-Sales.png'} alt="Building-Management-Software-Sales"/></Link> 
                <span>Building Management Software (Sales)</span>
            </div>
            <div  data-wow-delay="0.5s" data-aos="zoom-in" className="bounceInDown wow maindive1 text-center mx-auto">
             <Link  onClick={scrollToTop} to="/real-estate-software-in-pakistan"><img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Real-Estate-Software-Sales.png'} alt="Real-Estate-Software-Sales"/></Link>
                <span>Real Estate Software (Sales)</span>
            </div>   
                
        </div>
         <div className="mob1" >
            <div  data-wow-delay="0.1s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center">
             <Link  onClick={scrollToTop} to="/propertymanagementsystem"><img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Property-Management-Software-Sales.png'} alt="Property-Management-Software-Sales"/></Link>  
                <span>Property Management Software (Sales)</span>
            </div>
                <div  data-wow-delay="0.2s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center">
             <Link  onClick={scrollToTop} to="/construction-management-software">  <img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Construction-Management-Software.png'} alt="Construction-Management-Software"/></Link>
                <span>Construction-Management-Software</span>
            </div>
            <div  data-wow-delay="0.3s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center">
              <Link  onClick={scrollToTop} to="/accountsmanagementsoftware"> <img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Accounts-Management-Software.png'} alt="Accounts-Management-Software"/></Link>
                <span>Accounts-Management-Software</span>
            </div>
            <div  data-wow-delay="0.4s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center">
              <Link  onClick={scrollToTop} to="/billing-management-software"> <img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Society-Billing-Management-Software.png'} alt="Society-Billing-Management-Software"/></Link>
                <span>Society-Billing-Management-Software</span>
            </div>
            <div  data-wow-delay="0.5s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center">
               <Link  onClick={scrollToTop} to="/land-acquisition-management-system"><img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Land-Acquisition-Management.png'} alt="Land-Acquisition-Management"/></Link>
                <span>Land-Acquisition-Management</span>
            </div>
                
        </div>
        <div className="mob1">
         
            <div  data-wow-delay="0.1s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center">
               <Link  onClick={scrollToTop} to="/computerized-plot-balloting-for-housing-scheme"><img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Computerized-Plots-Balloting.png'} alt="Computerized-Plots-Balloting"/></Link>
                <span>Computerized-Plots-Balloting</span>
            </div>
            <div data-wow-delay="0.2s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center" >
              <Link  onClick={scrollToTop} to="/Marketing_Dealers_Commission_Management"> <img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Marketing-Dealers-Management.png'} alt="Marketing-Dealers-Management"/></Link>
                <span>Marketing-Dealers-Management</span>
            </div>  
            <div  data-wow-delay="0.3s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center" >
           <Link  onClick={scrollToTop} to="/biometric-registration-software"><img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Biometric-Registration-Verification.png'} alt="Biometric-Registration-Verification" /></Link>
            <span>Biometric-Registration-Verification</span>
        </div>
        <div data-wow-delay="0.4s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center"  >
          <Link  onClick={scrollToTop} to="/brand-sms"> <img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/SMS-Broadcaster.png'} alt="SMS-Broadcaster"/></Link>
            <span>SMS-Broadcaster</span>
        </div>
        <div data-wow-delay="0.5s" data-aos="zoom-in" className="bounceInDown wow  mx-auto maindive text-center" >
         <Link  onClick={scrollToTop} to="/project-sales-recovery-follow-up">  <img className="ourco" src={process.env.PUBLIC_URL + '/Images/Services/Sales-Recovery-Follow-up.png'} alt="Sales-Recovery-Follow-up"/></Link>
            <span>Sales-Recovery-Follow-up</span>
        </div>
        
          
    </div>
     </div>    
    </div>   
    </>
  );
}

export default Products;