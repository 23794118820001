import {useEffect} from 'react'
import "./realestatecrmleadsmanagementsystem.css"
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
const Realestatecrmleadsmanagementsystem = () => {
    useEffect(() =>{
        document.title="Real Estate CRM-Leads- Management System &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>
    <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center ">
            <h3 class="text-white animated slideInDown mb-4 mt-5">Real Estate CRM-Leads- Management System</h3>
        </div>
    </div>

    <div class="container-xxl py-6 p-4 courses" id="abouts">
                <div class="row">
                <div class="wow bounceInUp mon1"data-wow-delay="0.2s">
                    <div class="w-100 p-3">
                        <h4>CRM-Leads Management Software (Real Estate)</h4>
                        <p class="mb-5 acctextjus" >A Real Estate CRM-Leads Management System/CRM Management System is a powerful software solution designed specifically for real estate professionals to effectively manage leads, track customer interactions, and streamline sales processes. It provides a centralized platform to organize and nurture leads, ultimately enhancing customer relationships and driving sales growth. </p>                                                                                   
                    </div> 
                    <div class="align-items-center  gggg bounceInDown wow w-100 h-100 " data-wow-delay="0.3s" > 
                    <iframe class="vierr"  src="https://www.youtube.com/embed/KEAO_dtLXjY" title="Real Estate CRM Lead Management System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> </div>   
                                </div>                            
                            </div>
    <div class="container-xxl">
       
            <div class="wow bounceInUp mon1"data-wow-delay="0.3s">
                <div class="w-100">
                    <p class="mb-5 acctextjus" >By utilizing a Real Estate CRM-Leads Management System, real estate professionals can streamline their lead management processes, improve customer relationships, and boost sales performance. The system enhances organization, communication, and tracking, resulting in increased efficiency, improved lead conversion rates, and ultimately, greater revenue for real estate businesses.</p>                                                                                      
                </div> 
                </div>
                <div class=" max-auto wow bounceInUp disf" data-wow-delay="0.1s">
                    <div class="fa fa-check ffj"></div>
                        <p class="ml10">Real Estate CRM-Leads Management System/CRM Management System allows real estate professionals to capture leads from various sources, such as websites, social media, and marketing campaigns. It provides a centralized database to store and track leads throughout the sales cycle.</p>
                    </div>  
       <div class=" max-auto wow bounceInUp disf" data-wow-delay="0.2s">
        <div class="fa fa-check ffj"></div>
            <p class="ml10">Real Estate CRM-Leads Management System/CRM Management System enables personalized communication and engagement with leads. It facilitates automated email campaigns, drip marketing, and follow-up reminders to nurture relationships and move leads through the sales funnel.</p>
        </div>
        
    
    
        <div class=" max-auto wow bounceInUp disf" data-wow-delay="0.3s">
            <div class="fa fa-check ffj"></div>
                <p class="ml10">Real Estate CRM-Leads Management System/CRM Management System provides a comprehensive contact management system to store and manage customer information. It allows real estate professionals to track interactions, preferences, and property requirements of leads and existing customers, enabling personalized and targeted communication.</p>
            </div>
            <div class=" max-auto wow bounceInUp disf" data-wow-delay="0.4s">
                <div class="fa fa-check ffj"></div>
                    <p class="ml10">Real Estate CRM-Leads Management System/CRM Management System offers a visual representation of the sales pipeline, allowing users to track the progress of leads from initial contact to closing. It provides real-time updates on the status of each lead and helps sales teams prioritize their efforts effectively.</p>
                </div>
                <div class=" max-auto wow bounceInUp disf" data-wow-delay="0.5s">
                    <div class="fa fa-check ffj"></div>
                        <p class="ml10">Real Estate CRM-Leads Management System/CRM Management System includes task management capabilities to assign and track follow-up actions. It allows users to set reminders, schedule appointments, and track important activities, ensuring timely and efficient lead management.</p>
                    </div>
                    <div class=" max-auto wow bounceInUp disf" data-wow-delay="0.6s">
                        <div class="fa fa-check ffj"></div>
                            <p class="ml10">Real Estate CRM-Leads Management System/CRM Management System generates reports and provides analytics on lead generation, conversion rates, sales performance, and other key metrics. It offers insights into the effectiveness of marketing campaigns, sales team performance, and overall business growth, empowering real estate professionals to make data-driven decisions.</p>
                        </div>
    </div>   
<div class="p-3 demo1">
    <div class="p-2">
    <h4 class="text-white">CRM-Leads Management Software (Real Estate) includes:</h4>
</div>
</div>

<div class="container-xxl p-3" id="myGroup">
    <button class="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" href="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i class="bi bi-plus-circle phh1"></i>Lead</button><br/>
 
          <div class=" p-3 collapse" id="lead" data-bs-parent="#myGroup">

            <div class="fa fa-check" >Add Leads </div> <br/>
            <div class="fa fa-check " >Assign Leads (manual)</div><br/>
            <div class="fa fa-check " >Assign Leads (Auto)</div><br/>
            <div class="fa fa-check " >Assign Duplicate Lead</div><br/>
            <div class="fa fa-check " >Follow-up Lead</div><br/>
            <div class="fa fa-check " >Close Lead</div><br/>
            <div class="fa fa-check " >Delete Lead</div>
        </div> 
        <button class="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i class="bi bi-plus-circle phh1"></i>Follow Up Leads</button> 
        <div class="p-3 color-black  collapse" id="FollowUpLeads" data-bs-parent="#myGroup">
        
            <div class="fa fa-check " >Leads Filters</div><br/>
            <div class="fa fa-check " >Update Basic Information</div><br/>
            <div class="fa fa-check " >Add Key Notes</div><br/>
            <div class="fa fa-check " >Lead History</div><br/>
            <div class="fa fa-check " >Send SMS</div><br/>
            <div class="fa fa-check " >Check SMS Log</div><br/>
            <div class="fa fa-check " >Create Plan</div><br/>
            <div class="fa fa-check " >Check Plan History</div><br/>
            <div class="fa fa-check " >Print and send plan in PDF</div>
        
        </div>

        <button class="btttt bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i class="bi bi-plus-circle phh1"></i>Leads Status</button> 
        <div class="p-3  collapse  color-black" id="LeadsStatus" data-bs-parent="#myGroup">
        
            <div class="fa fa-check " >Active Leads</div><br/>
            <div class="fa fa-check " >Dead/Postponed Leads</div><br/>
            <div class="fa fa-check " >Successful Leads</div><br/>
            <div class="fa fa-check " >Delete Leads</div><br/>
            <div class="fa fa-check " >Over Date Leads</div><br/>
            <div class="fa fa-check " >Total Assign Leads</div><br/>
            <div class="fa fa-check " >Total Un-assigned Leads</div>
        </div>

        <button class="  btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i class="bi bi-plus-circle phh1"></i>Forward Leads</button> 
        <div class="p-3  collapse color-black"  id="ForwardLeads" data-bs-parent="#myGroup">
        
            <div class="fa fa-check " >Transferring leads from one user to another in the CRM system is a seamless process. The administrator or team lead can access the system, select the specific lead, and initiate the transfer to another user within a few clicks. This ensures a smooth transition of responsibility and maintains continuity in lead management.</div><br/>
          
            <div class="fa fa-check " >The transferred lead's details, including interactions and preferences, seamlessly move to the new user's account, allowing for a personalized and informed approach. This efficient transfer mechanism enhances team collaboration and ensures that leads receive consistent and tailored communication as they progress through the sales pipeline.</div><br/>
           
            <div class="fa fa-check " >Furthermore, the CRM system logs the lead transfer, providing transparency and accountability in the system. This feature aids in tracking changes, analyzing team performance, and maintaining a clear record of lead ownership transitions for future reference.</div>
        
        </div>
 
        <button class=" btttt bounceInUp wow" data-wow-delay="0.5s" data-bs-toggle="collapse" href="#Dashboard" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Dashboard" > <i class="bi bi-plus-circle phh1"></i>Leads Dashboard</button> 
        <div class="p-3  collapse color-black"  id="Dashboard" data-bs-parent="#myGroup">
        
            <div class="fa fa-check" >Admin Dashboard</div><br/>            
            <li class="fontsm">Check All Status User Wise</li>
            <li class="fontsm">Active Leads</li>
            <li class="fontsm">Meetings Arranged</li>
            <li class="fontsm">Total Meetings Done</li>
            <li class="fontsm">Late Follow-up</li>
            <li class="fontsm">Assigned Today</li>
            <li class="fontsm">(Will) Follow-up Today</li>
            <li class="fontsm">Today Follow-up (Done)</li>
            <li class="fontsm">Over Date Leads</li>
            <li class="fontsm">Successful Leads</li>
            <li class="fontsm">Postponed/Dead Leads</li>
            <li class="fontsm">Deleted Leads</li>
            <li class="fontsm">Continue Leads</li>
            <div class="fa fa-check " >User Dashboard</div>
<li class="fontsm">Active Leads</li>
<li class="fontsm">Meetings Arranged</li>
<li class="fontsm">Total Meetings Done</li>
<li class="fontsm">Assigned Today</li>
<li class="fontsm">(Will) Follow-up Today</li>
<li class="fontsm">Today Follow-up (Done)</li>
<li class="fontsm">Successful Leads</li>
<li class="fontsm">Postponed/Dead Leads</li>
<li class="fontsm">Deleted Leads</li>
<li class="fontsm">Continue Leads</li>
           
        </div>


     
        <button class="btttt  bounceInUp wow" data-wow-delay="0.6s" data-bs-toggle="collapse" href="#LeadsAnalysis" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsAnalysis" > <i class="bi bi-plus-circle phh1"></i>Leads Analysis</button> 
        <div class="p-3  collapse color-black" id="LeadsAnalysis" data-bs-parent="#myGroup">
        
            <div class="fa fa-check " >User TO User</div><br/>
            <div class="fa fa-check " >Admin Forword leads</div><br/>
            <div class="fa fa-check " >Redirect Leads</div>
        
        </div>

      
        <button class="btttt  bounceInUp wow" data-wow-delay="0.7s" data-bs-toggle="collapse" href="#LeadsReporting" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsReporting" > <i class="bi bi-plus-circle phh1"></i>Leads Reporting</button> 
        <div class="p-3  collapse color-black" id="LeadsReporting" data-bs-parent="#myGroup">
        
            <div class="fa fa-check " >Leads Report (Status Wise)</div><br/>
            <div class="fa fa-check " >User Login/Logout History</div><br/>
            <div class="fa fa-check " >Excel File History</div><br/>
            <div class="fa fa-check " >Referal Report</div>
        
        </div>

 
        <button class="btttt  bounceInUp wow" data-wow-delay="0.8s" data-bs-toggle="collapse" href="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i class="bi bi-plus-circle phh1"></i>Leads Import</button> 
        <div class="p-3  collapse color-black" id="LeadsImportTrough" data-bs-parent="#myGroup">
            <div class="fa fa-check " >Through Facebook</div><br/>
            <div class="fa fa-check " >Through Excel File</div><br/>
            <div class="fa fa-check " >Manual</div>
          
        
        </div>
 
        <button class="btttt  bounceInUp wow" data-wow-delay="0.9s" data-bs-toggle="collapse" href="#Facebookintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Facebookintegration" > <i class="bi bi-plus-circle phh1"></i>Facebook Integration</button> 
        <div class="p-3  collapse color-black"   id="Facebookintegration" data-bs-parent="#myGroup">
            <div class="fa fa-check " >Upload Facebook CSV. File</div>
        
        </div>
  
        <button class="btttt  bounceInUp wow" data-wow-delay="1s" data-bs-toggle="collapse" href="#SMSintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="SMSintegration" > <i class="bi bi-plus-circle phh1"></i>SMS integration</button> 
        <div class="p-3  collapse color-black" id="SMSintegration" data-bs-parent="#myGroup">
        
            <div class="fa fa-check " >SMS Drafts</div><br/>
            <div class="fa fa-check " >SMS Templates</div><br/>
            <div class="fa fa-check " >Send Single SMS</div><br/>
   <div class="fa fa-check " >Generate Bulk SMS</div><br/>
            <div class="fa fa-check " >Import Excel Numbers</div><br/>
            <div class="fa fa-check " >Send Bulk SMS</div>
        
        </div>
        </div>  
<Demo/>
        </div>  
    
    </>
  )
}

export default Realestatecrmleadsmanagementsystem