import React from 'react'
import "./Demobar.css";
import { Link } from 'react-router-dom';
const Demobar1 = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
    <div className="p-4 cc demo1" >
    <div className="p-3">
    <h4 className='text-white'>Ask For Quotation</h4>
    <span className='text-white main-style'>Empower your business with solutions. From conceptualization to deployment, our expert team ensures seamless development tailored to your needs. Drive innovation and efficiency with our cutting-edge services.</span>
</div>
<Link to="/contactus" onClick={scrollToTop} className="bttnn"> Ask for Quotation</Link>
</div>
    </>
  )
}

export default Demobar1