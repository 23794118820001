import {useEffect} from 'react'
import "./Digitalmarketing.css"
import Demo from "../../Components/Parts/Demobar/Demobar1.jsx"
const Digitalmarketing = () => {
    useEffect(() =>{
        document.title="Digital_Marketing &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
    return (
    <>
    <div className=" page-header  py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Digital Marketing</h3>
        </div>
    </div>
    <div className="container-xxl py-2">
       
    <div className="mrkdispflex p-4">
        <div className='markdiv'>
        <img className="immmr" src={process.env.PUBLIC_URL + '/Images/Products/Digital_Marketing_Service.png'} alt="Pakistan Top Real Estate Software Company, Top Ten Real Estate Software, Developers Management System, Building Sales Management System, Society Billing System, Maintenance Management System, Construction Management System, Open Form Trading System, Open Form QR Code Printing, QR Code Printing on Open Form, Real Estate File Trading System, Islamabad Housing Scheme Software, Real Estate Software Islamabad, Real Estate Software Faisalabad, Real Estate Software"/>
        </div>
        <div className='marktextdiv'>
        <h3>Digital Marketing Services</h3>
<p>Welcome to Quaid Soft ®, a distinguished leader in software development, now proudly leveraging our expertise in Digital Marketing Services. Since our inception in 2015, we have passionately delivered high-quality solutions to a diverse client, establishing ourselves as a trusted and reliable partner in the industry.</p>
<p>Our commitment to excellence is woven into the fabric of our services, ensuring that every digital marketing campaign we undertake is marked by innovation, effectiveness, and a strategic approach. As a registered entity, we adhere to the highest standards, providing clients with confidence in our ability to navigate the evolving landscape of digital marketing.</p>
<p>At Quaid Soft ®, we understand the importance of crafting tailored solutions for each client, recognizing that no two businesses are alike. This personalized approach has been the cornerstone of our success, allowing us to drive meaningful results and exceed expectations consistently.</p>
<p>Our journey in the industry has been marked by continuous growth and adaptation, staying at the forefront of technological advancements and industry trends. This commitment to staying ahead ensures that our clients benefit from cutting-edge strategies that deliver impactful and measurable outcomes.</p>
<p>As we extend our expertise to Digital Marketing Services, our goal remains unwavering – to empower businesses with a robust online presence, elevate brand visibility, and foster sustainable growth. Choose Quaid Soft ® for a digital journey where innovation meets reliability and success becomes a shared destination.</p>
</div>
<div>
</div>

   



    </div>

<h3 className="p-3">Digital Marketing Solutions:</h3>
<div className="w-100 p-4 wow fadeInUp "data-wow-delay="0.5s" >
    <div className="dd">
    <span className="colll  fa fa-check"></span>   
    <p className="pp" ><b>Search Engine Optimization (SEO):</b> Enhance your online visibility and search engine rankings through strategic SEO practices. Our experts ensure your business stays ahead in the digital landscape.</p> 
</div>
<div className="dd">
    <span className="colll  fa fa-check"></span>   
    <p className="pp" ><b>Social Media Marketing (SMM):</b> Engage, connect, and expand your audience with compelling social media campaigns. We tailor strategies to align with your brand and objectives, maximizing impact.</p> 
</div>
<div className="dd">
    <span className="colll  fa fa-check"></span>   
    <p className="pp" ><b>Content Marketing:</b> Fuel your brand's growth with impactful content. Our team creates and curates content that resonates with your target audience, driving brand awareness and loyalty.</p> 
</div>
<div className="dd">
    <span className="colll  fa fa-check"></span>   
    <p className="pp" ><b> Email Marketing:</b> Nurture leads and strengthen customer relationships through targeted and personalized email campaigns. Our goal is to optimize engagement and conversion rates.</p> 
</div>
<div className="dd">
    <span className="colll  fa fa-check"></span>   
    <p className="pp" ><b>Government Sector Engagement: </b> Quaid Soft ® proudly extends its digital expertise to the public sector. Our recent collaboration with the Ministry of Postal Services for the Track & Trace System of Safe City Projects e-Challan exemplifies our commitment to national initiatives.</p> 
</div>
</div>  
<Demo/>
    </div>       
    </>
  )
}

export default Digitalmarketing