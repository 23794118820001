import React from 'react'
import "./MarketingDealersCommissionManagement.css"
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
import { Link } from 'react-router-dom'
const MarketingDealersCommissionManagement = () => {
  return (
    <>
       <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Marketing/Dealers Commission Management (For Real Estate)</h3>
        </div>
    </div>
    <div class="container-xxl py-6 p-4 courses" id="abouts">
                <div class="row">
                <div class="wow  bounceInUp mon1"data-wow-delay="0.2s">
                    <div class="w-100 p-2">
                        <h4>Marketing/Dealers Commission Management(For Real Estate)
                            </h4>
                                                <p class="mb-2  acctextjus" >Is an automating software for the sales process to organize their entire customer accounts. Quaidsoft offers the best possible solution to<strong class="text-black"> Real Estate/Marketing/Dealers Commission Management Software </strong> in Pakistan. Quaid Soft provides Services all over Pakistan including major cities Lahore,Islamabad</p>
                                                <p class="mb-2 acctextjus" > <strong class="text-black">Marketing/Dealers Commission Management System</strong> For Real Estate Sector is a crucial aspect while selling real estate projects through Marketing Companies, Agent & Authorized Dealers. Commission Automation Process helps you to input the finalized Contracts of Contract to pay off the Commission. System will automatically calculate the Commission as per finalized contract & received amount of property. Stage to stage payable Commission calculation process and pay off process is a core functionality of this Module. 
                                                   </p>   

                                                   
                                                   <p className="mb-3 acctextjus" >Quaid Soft also provides <Link className="Link1" to="/accountsmanagementsoftware" > <b>Accounting software</b> </Link>,<Link className="Link1" to="/billing-management-software" > <b>Billing management system</b> </Link> , <Link className="Link1" to="/brand-sms" > <b>Brand Sms Software</b> </Link>,<Link className="Link1" to="/housing-scheme-software-in-pakistan" > <b>Inventory Management Software</b> </Link> ,<Link className="Link1" to="/construction-management-software" > <b>Construction Management Software</b> </Link> and Many others.</p>

                                                    <p class="mb-3  acctextjus" >Quaid soft gives 24/7 live support help our clients to achieve their milestones without any difficulty. Stay 24/7 connected with software through desktop. So whether you want to handle mid Real Estate operations or complex operations such as housing property operations, let our<strong class="text-black"> Marketing/Dealers Commission Management software </strong>automate your requirements and help you in your success mission in a managed way.</p>
                                                </div>   
                         
                            
                            </div>

<div class="p-3 demo1">
    <div class="p-2">
    <h4 class="text-white">Marketing/Dealers Commission Management Details </h4>
</div>
</div>

<div class="container-xxl p-3" id="myGroup">
    <button class="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" href="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i class="bi bi-plus-circle phh1" ></i>Marketing Company Information</button><br/>
 
          <div class=" p-3 collapse text-black" id="lead" data-bs-parent="#myGroup">
           
           
        </div> 


        <button class="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i class="bi bi-plus-circle phh1" ></i>Dealers /Agent Profile Details</button> 
        <div class="p-3  collapse text-black" id="FollowUpLeads"  data-bs-parent="#myGroup">
        
           
           </div>

        <button class="btttt bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i class="bi bi-plus-circle phh1" ></i>Commission Contract Payable Definition </button> 
        <div class="p-3  collapse text-black"  id="LeadsStatus" data-bs-parent="#myGroup">
        
            <li class="ontsm">Sale Price Commission</li>
            <li class="ontsm">Over & Above Commission</li>
            <li class="ontsm">Prime Features</li>
            <li class="ontsm">Any other Criteria can be add or customized as per need.</li>
        </div>

        <button class="  btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i class="bi bi-plus-circle phh1" ></i>Marketing/Dealer/Agent Definition on File/Plot/Apartment/Shop</button> 
        <div class="p-3  collapse text-black"   id="ForwardLeads" data-bs-parent="#myGroup">
        
        </div>
 
        

     
        <button class="btttt  bounceInUp wow" data-wow-delay="0.6s" data-bs-toggle="collapse" href="#LeadsAnalysis" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsAnalysis" > <i class="bi bi-plus-circle phh1" ></i>Auto Calculation of Payable Commission on Payment Received </button> 
        <div class="p-3  collapse text-black"   id="LeadsAnalysis" data-bs-parent="#myGroup">
        
           
        </div>

      
        <button class="btttt  bounceInUp wow" data-wow-delay="0.7s" data-bs-toggle="collapse" href="#LeadsReporting" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsReporting" > <i class="bi bi-plus-circle phh1" ></i>Finalization Of Payable Commission</button> 
        <div class="p-3  collapse text-black"   id="LeadsReporting" data-bs-parent="#myGroup">
        
           
  
        </div>

 
        <button class="btttt  bounceInUp wow" data-wow-delay="0.8s" data-bs-toggle="collapse" href="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i class="bi bi-plus-circle phh1" ></i>Payment Against Payable Commission to  Marketing Company/Dealers & Agent</button> 
        <div class="p-3  collapse text-black"   id="LeadsImportTrough" data-bs-parent="#myGroup">
            
           
        </div>
        <button class="btttt bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i class="bi bi-plus-circle phh1" ></i>Commission Reports</button> 
        <div class="p-3  collapse text-black"   id="LeadsStatus" data-bs-parent="#myGroup">
        
            <li class="ontsm">Commission Due As on Today</li>
            <li class="ontsm">Commission Paid on as on Today</li>
            <li class="ontsm">Commission Summary of Whole project Sale</li>
            <li class="ontsm">Dealers wise Recovery Reports</li>
                 <li class="ontsm">Dealer wise Payable Reports</li>
        </div>

        <button class="btttt  bounceInUp wow" data-wow-delay="0.8s" data-bs-toggle="collapse" href="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i class="bi bi-plus-circle phh1" ></i>Commission Inquiries for Staff</button> 
        <div class="p-3  collapse text-black"   id="LeadsImportTrough" data-bs-parent="#myGroup">
        </div>    
        </div>  
        </div> 
        <Demo/>
    </div>
    </>
  )
}

export default MarketingDealersCommissionManagement