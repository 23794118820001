import {useEffect} from 'react'
import "./Gallery.css"
import img from "./image-removebg-preview (7).png"
const Gallery = () => {
  useEffect(() =>{
    document.title="Gallery | Quaid Soft | ERP Software Company In Pakistan";
},[]);
  return (
    <>
      <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center ">
            <h3 class="text-white animated slideInDown mb-4 mt-5">Gallery</h3>
        </div>
    </div>
    
    <div class="container-xxl courses p-0 ">
        <div class="row p-4 text-center">
            <img class="w-50 mmkj" src={img} alt=""/>

        </div>  </div>
    
    
    </>
  )
}

export default Gallery