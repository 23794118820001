import {useEffect} from 'react'
import "./Aboutus.css"
import Aboutus1 from '../../Components/Parts/Aboutus/Aboutus'
const Aboutus = () => {
 useEffect(() =>{
  document.title="about us-Quaid Soft | ERP Software Company In Pakistan";
},[]);
  return (
   <>
    <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center ">
            <h3 class="text-white animated slideInDown mb-4 mt-5">About Us</h3>
        </div>
    </div>
    <div className='mt-3 courses  container-xxl'>
    <div className=' row p-4'>
      <Aboutus1/>
    </div>
    </div>
   </>
  )
}

export default Aboutus