import {useEffect} from 'react';
import "./Projectconsultancy.css";
import Demo from "../../Components/Parts/Demobar/Demobar1.jsx"
const Projectconsultancy = () => {
    useEffect(() =>{
        document.title="Project Consultancy Services Management Pakistan Sialkot, Lahore, Islamabad, Faisalabad &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>
    <div className=" page-header  py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Real Estate Project Consultancy</h3>
        </div>
    </div>
    <div className="container-xxl courses py-2">
        <div className='row p-4'>
                <div className="prodisplayf">                                                        
                    <div className='proimgdiv'>
                     <img className="prowebimg" src={process.env.PUBLIC_URL + '/Images/Products/project-consultancy-1.jpeg'} alt="Project Consultancy Services Management Pakistan Sialkot, Lahore, Islamabad"/>  
                     </div>               
                   <div className="w-100 p-4" >
                    <p className="mb-4 protext color-black"> 
                        With a wealth of 15 years of experience and a robust portfolio boasting over 600 satisfied clients across Pakistan, 
                        our Real Estate Project Automation Consultancy stands as a testament to our expertise and commitment. At Quaid Soft,
                         we specialize in streamlining and enhancing real estate projects (Housing or Building Project) sales through cutting-edge
                          automation solutions. Our  consulting services are designed to optimize efficiency in sales, documentation, minimize risks
                           and elevate overall project & team performance. Drawing from our extensive experience, we provide tailored advice and strategies 
                           that align seamlessly with the unique challenges of the real estate sector. Trust us to be your strategic real estate automation 
                           partner in navigating the complexities of real estate project automation, ensuring successful outcomes and continued growth 
                           for your ventures across Pakistan.</p> <br/>
                 
                    </div>        
                   
                    </div>                           

<div className='row p-4'>
        <h3 className="p-3" >Consultancy Scope</h3>
   <div className='prodisplayf'>
    <div className="w-100 p-2 wow fadeInUp "data-wow-delay="0.5s" >
        <div className="ppq">
        <span className="fa fa-check colll"></span>   
        <p className="pp" >Project Planning </p> 
    </div>
    <div className="ppq">
        <span className="fa fa-check colll"></span>   
        <p className="pp" >Print Media Campaign </p> 
    </div>
    <div className="ppq">
        <span className="fa fa-check colll"></span>   
        <p className="pp" >Electronic Media Campaign</p> 
    </div>
    <div className="ppq">
        <span className="fa fa-check colll"></span>   
        <p className="pp" >Hiring Source of Work</p> 
    </div>

        
        </div>        
       
        <div className="w-100 p-2 wow fadeInUp " data-wow-delay="0.5s" >
            <div className="ppq">
            <span className="fa fa-check colll"></span>   
            <p className="pp" >Distribution of Work</p> 
        </div>
        <div className="ppq">
            <span className="fa fa-check colll"></span>   
            <p className="pp" >Business Trainings</p> 
        </div>
        <div className="ppq">
            <span className="fa fa-check colll"></span>   
            <p className="pp" >Software Trainings</p> 
        </div>
        <div className="ppq">
            <span className="fa fa-check colll"></span>   
            <p className="pp" >Customer Care Trainings</p> 
        </div>
          
            
            </div>   
            <div className="w-100 p-2 wow fadeInUp " data-wow-delay="0.5s" >
                <div className="ppq">
                <span className="fa fa-check colll"></span>   
                <p className="pp" >Marketing Strategies</p> 
            </div>
            <div className="ppq">
                <span className="fa fa-check colll"></span>   
                <p className="pp" >Customer Follow up Training</p> 
            </div>
            <div className="ppq">
                <span className="fa fa-check colll"></span>   
                <p className="pp" >Task Assignment</p> 
            </div>
            <div className="ppq">
                <span className="fa fa-check colll"></span>   
                <p className="pp" >Internal/External Communication </p> 
            </div>
              
                
                </div>
                <div className="w-100 p-2 wow fadeInUp " data-wow-delay="0.5s" >
                    <div className="ppq">
                    <span className="fa fa-check colll"></span>   
                    <p className="pp" >MIS Reporting</p> 
                </div>
                <div className="ppq">
                    <span className="fa fa-check colll"></span>   
                    <p className="pp" >Customers Business Documentation</p> 
                </div>
                    
                    </div>      
                    </div>
                    </div>
                    </div>  
                    <Demo/>
                    </div>
  

    </>
  )
}

export default Projectconsultancy