import {useEffect} from 'react'
import "./Product.css";
import Products from "../../Components/Parts/Products/Products";

const Product = () => {
  useEffect(() =>{
    document.title="products &#8211; Quaid Soft | ERP Software Company In Pakistan";
},[]);
  return (
      <>
    <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center ">
            <h3 class="text-white animated slideInDown mb-4 mt-5">Products</h3>
        </div>
    </div>
    <div className='container-xxl courses'>
    <div className=' row mb-5'>
      <Products/>
    </div>
    </div>
   </>
  )
}

export default Product