import {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
const CEO = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>    <div className="container-xxl  " >
        <div className="row p-4"  >
            <div className="row g-0 team-items justify-content-center">
                <div className=" col-md-6 p-2">
                    <div className="position-relative  " data-aos="zoom-in-up">  
                    <h3 className='mt-2'>Management Message</h3>
                            <p className="mt-2 pjust" >Quaid Soft is purely a <Link className='textnon' to="/real-estate-software-in-pakistan" onClick={scrollToTop}><b>Real Estate Software</b></Link> automation company. Its management (Mian Azhar and Muhammad Mustafa) have 15+ years of experience in <Link className='textnon' onClick={scrollToTop} to="/real-estate-software-in-pakistan"><b>Real Estate Software</b></Link> automation, serving approximately 600+ <Link className='textnon' onClick={scrollToTop} to="/real-estate-software-in-pakistan"><b>Real Estate Housing Software</b></Link>  and high-rise <Link className='textnon' onClick={scrollToTop} to="/building-sales-management-software-sales"><b>Building Projects Software</b></Link> in all provinces of Pakistan. Its success story is carved by the untiring hard work, dedication, and adherence of the Quaid Soft team to its cause. For our clients, Quaid Soft is a name that stands for its assistance to customers, commitment to work, long-term relationships, customer care, customized solutions, application integrity, product and domain knowledge, retainer ship services, and competitive prices. Quaid Soft was properly registered as a software and IT company in 2015. We have vast experience in customized <Link className='textnon' onClick={scrollToTop} to="/real-estate-software-in-pakistan"><b> Real Estate Software Development</b></Link> ,<Link className='textnon' onClick={scrollToTop} to="/web-development"><b> Web Development</b></Link> , business automation, and other corporate sectors, with a broad vision.</p>
                    </div>
                </div>
                <div className="col-lg-3 mt-2 col-md-6 wow fadeInUp"  data-aos="zoom-in-up">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Mian Azhar (Managing Director Quaidsoft).jpg'} alt=""/>
                            <div className="team-social text-center">
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://www.facebook.com/mianazharhussain.pk"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://twitter.com/azharhussain_pk"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://www.instagram.com/azharhussain_pk/"><i className="fab fa-instagram"></i></Link>
                            </div>
                        </div>
                        <div className="bg-light text-center p-4">
                            <h5 className="mt-2 text-black">Mian Azhar Hussain</h5>
                            <span>Managing Director</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 mt-2 col-md-6 "  data-aos="zoom-in-up">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Team/Mustafa (CEO QuaidSoft).png'} alt=""/>
                            <div className="team-social text-center">
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://www.facebook.com/yehchees"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="#"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-square btn-outline-primary border-2 m-1" target="_blank" to="https://www.instagram.com/yehchees/?igsh=MWQ0bm11aGY3MGlyeg%3D%3D"><i className="fab fa-instagram"></i></Link>
                            </div>
                        </div>
                        <div className="bg-light text-center p-4">
                            <h5 className="mt-2 text-black">Muhammad Mustafa</h5>
                            <span>Chief Executive Officer</span>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    
    
    </div>
    </>

  );
}

export default CEO;