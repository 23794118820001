import {useEffect} from 'react'
import "./realestatecrmleadsmanagementsystem.css";
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
const Openformtradingwithqrforhousingbuilding = () => {
    useEffect(() =>{
        document.title="Open Form Trading with (QR) For Housing/Building &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>
     <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Open Form Trading With (QR) For Housing/Building</h3>
        </div>
    </div>
    <div className="container-xxl py-6 courses" id="abouts">
        <div className="row p-4">
            <div className="wow  bounceInUp mon1" data-wow-delay="0.2s">
                <div className="w-100 p-2">
                    <h4>Open Form Trading With (QR) For Housing/Building</h4>
                    <p className="mb-5 acctextjus">Quaid Soft Providing in real estate
                        market an <strong className="text-black"> Open Form Trading with (QR) For Housing/Building
                        </strong> with QR Code (Web Verification). Open Form could be used to facilitate the buying and
                        selling of properties in real estate.</p>
                </div>
            </div>
            <div className="wow bounceInUp" data-wow-delay="0.3s">
                <p>Here’s how it could work:</p>
            </div>
        </div>

        <div className="container-xxl  ">


            <div className=" max-auto wow bounceInUp disf" data-wow-delay="0.1s">
                <div  className="fa fa-check ffj"></div>
                <p className="ml10">In Real Estate, seller lists their property on the Open Form Trading
                    System, providing details such as the property’s location, size, features, and asking price and also
                    add in open form trading what do you want in Real E-State.</p>
            </div>
            <div className=" max-auto wow bounceInUp disf" data-wow-delay="0.2s">
                <div className="fa fa-check ffj"></div>
                <p className="ml10">In the Real E-State, Interested buyers can then browse the Open form QR
                    code to access additional information about the property, such as photos, videos, and virtual tours.
                </p>
            </div>



            <div className=" max-auto wow bounceInUp disf" data-wow-delay="0.3s">
                <div className="fa fa-check ffj"></div>
                <p className="ml10">In the Real E-State, if a buyer is interested in making an offer, they can
                    submit a bid through the platform, which is then securely transmitted to the seller for
                    consideration.</p>
            </div>
            <div className=" max-auto wow bounceInUp disf" data-wow-delay="0.4s">
                <div className="fa fa-check ffj"></div>
                <p className="ml10">In the Real E-State the Open Form Trading ensure the integrity of the
                    system, both buyers and sellers would need to undergo a verification process to confirm their
                    identities and prevent fraud.</p>
            </div>
            <p className="mb-5 acctextjus wow bounceInUp" data-wow-delay="0.4s"  >By
                using an Open Form Trading System with QR Code (Web Verification) in Real E-State Market buyers and
                sellers can benefit from greater transparency, security, and efficiency in the real estate transaction
                process.</p>

        </div>

        <div className="p-3 demo1">
            <div className="p-2">
                <h4 className="text-white">Open Form Trading With (QR) For Housing/Building includes:</h4>
            </div>
        </div>

        <div className="container-xxl mt-3">
            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Autroized Delaer Details Management</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>General Public Details Management</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Currency Notes Inventory Generation</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>QR Code Generation as Bulk for Open Form</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>QR Code Generation with URL + Currency Note Number</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Printing of Open Form with QR Code</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Affixing of Currency Notes on Open Farm</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Issuance of Open Form to Dealers / General Public</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Issue Rate / Submission Rate of Open Form</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Payment Receiving against Open Forms</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Dealers Wise Complete Inquiry of Form Issuance / Submission Status</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Inquiry of Open From (Dealers Wise)</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Submission of Open Form via Currency Note as Customer File</button>

            <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads"
            data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads"> <i
                className="fa fa-check phh1">
            </i>Inquiry of Open From (Dealers Wise)</button>
            </div>
           <Demo/>
        </div>

    </>
  )
}

export default Openformtradingwithqrforhousingbuilding