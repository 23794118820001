import {useEffect} from 'react'
import "./projectsalesrecoveryfollowup.css"
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
const Projectsalesrecoveryfollowup = () => {
    useEffect(() =>{
        document.title="Project Sales Recovery Follow-up &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
   <>
     <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Project Sales Recovery Follow-Up</h3>
        </div>
    </div>

    <div class="container-xxl py-6 p-4 courses" id="abouts">
                <div class="row">
                <div class="wow  bounceInUp mon1"data-wow-delay="0.2s">
                    <div class="w-100 p-2">
                        <h4>Project Sales Recovery Follow-Up</h4>
                        <p class="mb-3 acctextjus" >A Sales Recovery Follow-up/ Recovery Follow-up Software is fully Integrate in ERP Sales System and Account System. A Sales Recovery Follow-up/ Recovery Follow-up Software  is a specialized tool designed to assist Real E-State professionals in managing the process of recovering payments from delinquent clients or tenants From Quaid Soft ERP System / Real Estate Software (Sales).  It provides an organized and efficient system for tracking, monitoring, and following up on overdue payments, thereby maximizing the chances of successful recovery.</p>                                                                                   
                        <p class="mb-3 acctextjus" ><b>Features of a Recovery Follow-up Software / Sales Recovery Follow-up from Real E-State.</b></p>
                    </div> 
                                </div>                            
                               
                                    
                                
                            </div>
    <div class="container-xxl  ">
       
            
                <div class=" max-auto wow bounceInUp disf"  data-wow-delay="0.1s">
                    <div class="fa fa-check  ffj"></div>
                        <p class="ml10">Sales Recovery Follow-up/ Recovery Follow-up Software enables users to record and track payment details, including due dates, amounts, and payment history. It allows real estate professionals to easily identify and monitor delinquent accounts.</p>
                    </div>  
       <div class=" max-auto wow bounceInUp disf"  data-wow-delay="0.2s">
        <div class="fa fa-check  ffj"></div>
            <p class="ml10">Sales Recovery Follow-up/ Recovery Follow-up Software automates the process of sending payment reminders to clients or tenants. It can be set up to send automated email notifications or SMS alerts to remind individuals about their outstanding payments.</p>
        </div>
        
    
    
        <div class=" max-auto wow bounceInUp disf"  data-wow-delay="0.3s">
            <div class="fa fa-check  ffj"></div>
                <p class="ml10">Sales Recovery Follow-up/ Recovery Follow-up Software offers customizable templates for payment reminder messages, which can be personalized based on specific client or tenant details. This ensures a professional and consistent communication approach.</p>
            </div>
            <div class=" max-auto wow bounceInUp disf"  data-wow-delay="0.4s">
                <div class="fa fa-check  ffj"></div>
                    <p class="ml10">Sales Recovery Follow-up/ Recovery Follow-up Software includes task management capabilities to assign and track follow-up actions. It allows users to set deadlines, assign responsibilities, and track the progress of payment recovery efforts.</p>
                </div>
                <div class=" max-auto wow bounceInUp disf"  data-wow-delay="0.4s">
                    <div class="fa fa-check  ffj"></div>
                        <p class="ml10">Sales Recovery Follow-up/ Recovery Follow-up Software maintains a log of all communication activities related to payment recovery, including emails, phone calls, and notes. This provides a comprehensive history of interactions, facilitating better communication and informed decision-making..</p>
                    </div>
                    <div class=" max-auto wow bounceInUp disf"  data-wow-delay="0.4s">
                        <div class="fa fa-check  ffj"></div>
                            <p class="ml10">Sales Recovery Follow-up/ Recovery Follow-up Software generates reports and provides analytics on payment recovery performance. It offers insights into recovery rates, average time to collect, and other key metrics, enabling real estate professionals to evaluate their effectiveness and make data-driven decisions.</p>
                        </div>
                <p class="mb-5 acctextjus wow bounceInUp" data-wow-delay="0.4s" >By utilizing a Sales Recovery Follow-up/ Recovery Follow-up Software, real estate professionals can streamline their payment recovery processes, reduce manual efforts, and improve their chances of successful collection. The software enhances organization, communication, and tracking, resulting in improved efficiency and optimized revenue management for real estate businesses.</p>                                                                                   
  
    </div>   
      
<div class="p-3 demo1">
    <div class="p-2">
    <h4 class="text-white">Key Functionalities of Project Sales Recovery Follow-up:</h4>
</div>
</div>

<div class="container-xxl p-3" id="myGroup">
    <button class="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" href="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i class="bi bi-plus-circle phh1" ></i>User Management</button><br/>
 
          <div class=" p-3 collapse color-black" id="lead" data-bs-parent="#myGroup">
            <li class="fontsm">User Creation</li>
            <li class="fontsm">User Rights to perform their tasks</li>
           
    
        </div> 


        <button class="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i class="bi bi-plus-circle phh1" ></i>Client / File assignment to User</button> 
        <div class="p-3  collapse color-black" id="FollowUpLeads"   data-bs-parent="#myGroup">
            <li class="fontsm">Files / Clients assignment to user</li>
            <li class="fontsm">Conversation Start & Finish Time & Chat History</li>
            <li class="fontsm">Admin can shift any Client / File to any other user</li>
            

        </div>

        <button class="btttt bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i class="bi bi-plus-circle phh1" ></i>Tasks Updates</button> 
        <div class="p-3  collapse color-black" id="LeadsStatus" data-bs-parent="#myGroup">
            <li class="fontsm">Task Initialization (Next due Task Date and Remarks / Instructions)</li>
            <li class="fontsm">Set Task Due date</li>
            <li class="fontsm">Add Task what discussion should be done with client</li>
            <li class="fontsm">After discussion add current communication in current date.</li>
            <li class="fontsm">Add next follow up / task date with Client.</li>
            <li class="fontsm">User can add call timing, how long discussion done by client</li>     
   
        </div>

        <button class="  btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i class="bi bi-plus-circle phh1" ></i>Task Inquiry</button> 
        <div class="p-3  collapse color-black" id="ForwardLeads" data-bs-parent="#myGroup">
            <li class="fontsm">User can view his / her tasks inquiry date wise</li>
            <li class="fontsm">System will show current due task on top of the list</li>
            <li class="fontsm">User can view client / file communication history by one Click.</li>
            <li class="fontsm">SMS Sending of Over DUE Amount to Client.</li>
        
        </div>
 
        <button class=" btttt  bounceInUp wow" data-wow-delay="0.5s" data-bs-toggle="collapse" href="#Dashboard" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Dashboard" > <i class="bi bi-plus-circle phh1" ></i>Reports</button> 
        <div class="p-3  collapse color-black" id="Dashboard" data-bs-parent="#myGroup">
        
            <li class="fontsm">Recovery Report user wise as per assigned Client / File.</li>
            <li class="fontsm">Tasks Report user and date wise.</li>
        </div>


        </div>
        <Demo/>
        </div>  


   </>
  )
}

export default Projectsalesrecoveryfollowup