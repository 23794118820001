import {useEffect} from 'react'
import "./Team.css";
import Team1 from "../../Components/Parts/Team/Team";
const Team = () => {
  useEffect(() =>{
    document.title="team - Quaid Soft | ERP Software Company In Pakistan";
},[]);
  return (
    <>
    <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center ">
            <h3 class="text-white animated slideInDown mb-4 mt-5">Quaid Soft Team</h3>
        </div>
    </div>
    <div className='courses container-xxl'>
    <div className='p-4'>
      <Team1/>
    </div>
    </div>
    </>
  )
}

export default Team