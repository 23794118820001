import {useEffect} from 'react'
import "./landacquisitionmanagementsystem.css"
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
import { Link } from 'react-router-dom'
const Landacquisitionmanagementsystem = () => {
  useEffect(() =>{
    document.title="Land Acquisition Management System &#8211; Quaid Soft | ERP Software Company In Pakistan";
},[]);
  return (
    <>
      <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Land Acquisition Management System</h3>
        </div>
    </div>

      
    <div className="container-xxl py-6 p-4 courses" id="abouts">
                <div className="row">
                <div className="wow  bounceInUp mon1"data-wow-delay="0.2s">
                    <div className="w-100 p-2">
                        <h4>Land Acquisition Management System</h4>
                                                <p className="mb-2 acctextjus" >Land Acquisition Management System/Land Record Management System specially designed for Developers, Builders and land owners to keep the record of Land Purchasing. Land Acquisition Management System/Land Management System is also integrated With Account Management System to keep the track of financial Flow as well. Land Record System is directly a part of our <strong className="text-black"> ERP Software for Real Estate</strong>. However, it may be operational/Installed as Stand-Alone Application. Land Acquisition Management System/Land Management System specially develop to keep the day to day needs of land record statistics e.g Khasra, Radeef, Mozah, Khata No., Khatoni, District, Tehsil ,Acre, Kanal, Marla, Sqft, Sarsahi etc. Land Record Management System is a best Record Automation Toll in Real Estate Sector as core requirement of Land Developers, Land Builders, Construction Companies and all kind of Real Estate Stack holders directly or in-directly involve in land purchasing sector.</p>
                                                <p className="mb-2 acctextjus" >Using a Land Acquisition Management System can help real estate developers and investors improve their efficiency and effectiveness in acquiring land for new projects. The Land Acquisition Management System can help to streamline processes, improve communication and collaboration, and provide valuable data and insights.
                                                   </p>   
                                                   
                                                   <p className="mb-3 acctextjus" >Quaid Soft also provides <Link className="Link1" to="/accountsmanagementsoftware" > <b>Accounting software</b> </Link>,<Link className="Link1" to="/billing-management-software" > <b>Billing management system</b> </Link> , <Link className="Link1" to="/brand-sms" > <b>Brand Sms Software</b> </Link>,<Link className="Link1" to="/housing-scheme-software-in-pakistan" > <b>Inventory Management Software</b> </Link> ,<Link className="Link1" to="/construction-management-software" > <b>Construction Management Software</b> </Link> and Many others.</p>

                                                    <p className="mb-3 acctextjus" >Quaid Soft gives 24/7 live support help our clients to achieve their milestones without any difficulty. Stay 24/7 connected with software through desktop. So whether you want to handle mid Real Estate operations or complex operations such as housing property operations, let our<strong className="text-black"> Land Acquisition Management System </strong>automate your requirements and help you in your success mission in a managed way.</p>
                                                </div>   
                         
                            
                            </div>
<div className="p-3 demo1">
    <div className="p-2">
    <h4 className="text-white">Land Acquisition Management System includes:</h4>
</div>
</div>

<div className="container-xxl p-3" id="myGroup">
    <button className="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" href="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i className="bi bi-plus-circle phh1"></i>Land Deals</button><br/>
 
          <div className=" p-3 collapse text-black"  id="lead" data-bs-parent="#myGroup">
            <li className="fontsm">Deal Name, Deal Date, Mature Date</li>
            <li className="fontsm">Deal Persons, Deal With, Land Owners Details</li>
            <li className="fontsm">Purchase Land Details</li>
            <li className="fontsm">Land Status (Disputed/Clear)</li>
            <li className="fontsm">Land purchase Cost</li>
            <li className="fontsm">Land Payment Pay Off Plan</li>
           
        </div> 


        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i className="bi bi-plus-circle phh1"></i>Land Document Details</button> 
        <div className="p-3  collapse text-black" id="FollowUpLeads"   data-bs-parent="#myGroup">
        
            <li className="fontsm">Land Purchase Document (Stamp Paper)</li>

            <li className="fontsm">Fard, registry, other document attachment</li>
           
           
           </div>

        <button className="btttt bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i className="bi bi-plus-circle phh1"></i>Land Owners Details</button> 
        <div className="p-3  collapse text-black"id="LeadsStatus" data-bs-parent="#myGroup">
        
            <li className="fontsm">Land Measurement Owner Wise</li>
        </div>

        <button className="  btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i className="bi bi-plus-circle phh1"></i>Land Agent Details</button> 
        <div className="p-3  collapse text-black"  id="ForwardLeads" data-bs-parent="#myGroup">
        
          <li className="fontsm">Agent Commission Calculation</li>
          <li className="fontsm">Payment to land Agent</li>
        </div>

     
        <button className="btttt  bounceInUp wow" data-wow-delay="0.6s" data-bs-toggle="collapse" href="#LeadsAnalysis" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsAnalysis" > <i className="bi bi-plus-circle phh1"></i>Land Deal Expense</button> 

      
        <button className="btttt  bounceInUp wow" data-wow-delay="0.7s" data-bs-toggle="collapse" href="#LeadsReporting" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsReporting" > <i className="bi bi-plus-circle phh1"></i>Land Witness Details</button> 

 
        <button className="btttt  bounceInUp wow" data-wow-delay="0.8s" data-bs-toggle="collapse" href="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i className="bi bi-plus-circle phh1"></i>Expenses made on Land Purchasing</button> 
  
 
        <button className="btttt  bounceInUp wow" data-wow-delay="0.9s" data-bs-toggle="collapse" href="#Facebookintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Facebookintegration" > <i className="bi bi-plus-circle phh1"></i>Payment Against Land</button> 
        <div className="p-3  collapse text-black"   id="Facebookintegration" data-bs-parent="#myGroup">
        
            <li className="fontsm">Cancellation due to Non Payments for a long time</li>
            <li className="fontsm">Cancellation Date, Cancellation Reason, Cancellation Officer</li>
            
            

        </div>
  
        <button className="btttt  bounceInUp wow" data-wow-delay="1s" data-bs-toggle="collapse" href="#SMSintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="SMSintegration" > <i className="bi bi-plus-circle phh1"></i>Land Deal Finalization / Edit</button> 
        <div className="p-3  collapse text-black"   id="SMSintegration" data-bs-parent="#myGroup">
        
           


        </div>  

        <button className="btttt  bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#drp1" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp1" > <i className="bi bi-plus-circle phh1"></i>Land Deal Inquiry</button> 
        <div className="p-3  collapse text-black" id="drp1" data-bs-parent="#myGroup">
        
        
        </div>  
        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#drp2" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp2" > <i className="bi bi-plus-circle phh1"></i>Land Deal Document Printing</button> 
        <div className="p-3  collapse text-black"   id="drp2" data-bs-parent="#myGroup">
        
           
        </div>  

        <button className="btttt  bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#drp3" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp3" > <i className="bi bi-plus-circle phh1"></i>Land Deal Documents Attachment</button> 
        <div className="p-3  collapse text-black"  id="drp3" data-bs-parent="#myGroup">
        
    
            
        </div>  
        <button className="btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#drp4" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp4" > <i className="bi bi-plus-circle phh1"></i>Many Other Flow of Land</button> 
        <div className="p-3  collapse text-black"  id="drp4" data-bs-parent="#myGroup">
        
           
        </div>  
       
        <button className="btttt  bounceInUp wow" data-wow-delay="0.5s" data-bs-toggle="collapse" href="#drp5" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp5" > <i className="bi bi-plus-circle phh1"></i>Reports of Land Management system</button> 
        <div className="p-3  collapse text-black"  id="drp5" data-bs-parent="#myGroup">
        
       

        </div>  
        </div>  
        </div>  
        <Demo/>
        </div>  
        


       
    </>
  )
}

export default Landacquisitionmanagementsystem