import {useEffect} from 'react'
import "./propertymanagementsystem.css"
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
import { Link } from 'react-router-dom'
const Propertymanagementsystem = () => {
    useEffect(() =>{
        document.title="Property Management System, Best Property Management System, Top Property Management System, Software For Property Dealer, Construction Management System, Developers Management System, Building Sales Management System, Maintenance Management System &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>
        <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Property Management System</h3>
        </div>
    </div>

    
    <div class="container-xxl py-6 p-4 courses" id="abouts">
                <div class="row">
                <div class="wow  bounceInUp mon1"data-wow-delay="0.2s">
                    <div class="w-100 p-2">
                        <h4>Property Management System</h4>
                                                <p class="mb-3 acctextjus" >Is an automating software for the sales process to organize their entire customer accounts. Quaidsoft offers the best possible solution to Real Estate/Property Management Software in Pakistan. Quaid Soft provides Services all over Pakistan including major cities Lahore,Islamabad.</p>
                                                <p class="mb-3 acctextjus" >Revenue increase and decrease can be affected by this system too. Also, this system can provide statistical analysis which shows how each sales staff  does during the sales process and sales cycles. In addition,<strong class="text-black"> Property Management System</strong> is one of the sales and operational CRM technologies to increase customers’ satisfaction for the sales department.
                                                   </p>   
                                                   <strong class="mb-3 acctextjus text-black"  > The Housing Scheme Management System is for both Housing and Commercial Projects.</strong>

                                                   <p class="mb-3 acctextjus" > <strong class="text-black">Our Property Management System</strong>  in  Pakistan Real Estate industry  is designed to also optimize sales management processes by improving customer service, evaluating sales activities, capturing trends, and performing an advanced statistical analysis.<strong class="text-black">Real Estate Management Software</strong> provides comprehensive reports for managing the Real Estate agency performance and efficiency, and enables the management for better decision-making.</p>
                                                   
                                                   <p className="mb-3 acctextjus" >Quaid Soft also provides <Link className="Link1" to="/accountsmanagementsoftware" > <b>Accounting software</b> </Link>,<Link className="Link1" to="/billing-management-software" > <b>Billing management system</b> </Link> , <Link className="Link1" to="/brand-sms" > <b>Brand Sms Software</b> </Link>,<Link className="Link1" to="/housing-scheme-software-in-pakistan" > <b>Inventory Management Software</b> </Link> ,<Link className="Link1" to="/construction-management-software" > <b>Construction Management Software</b> </Link> and Many others.</p>

                                                    <p class="mb-3 acctextjus"  >Quaid soft gives 24/7 live support help our clients to achieve their milestones without any difficulty. Stay 24/7 connected with software through desktop. So whether you want to handle mid Real Estate operations or complex operations such as housing property operations, let our <strong class="text-black">Property Management System</strong> automate your requirements and help you in your success mission in a managed way.</p>
                                                </div>   
                                   <div  >  <img class="gg11" src={process.env.PUBLIC_URL + '/Images/Services/Property-Management-System-Best-Top-Software-For-Dealer-Construction-Developers-Building-Sales-Maintenance-System-pu2bbzetwl58w6isyqj5pwickm71uxfwkofcoyox38.png'} alt="Pakistan Top Real Estate Software Company, Top Ten Real Estate Software, Developers Management System, Building Sales Management System, Society Billing System, Maintenance Management System, Construction Management System, Open Form Trading System, Open Form QR Code Printing, QR Code Printing on Open Form, Real Estate File Trading System" title="Pakistan Top Real Estate Software Company, Top Ten Real Estate Software, Developers Management System, Building Sales Management System, Society Billing System, Maintenance Management System, Construction Management System, Open Form Trading System, Open Form QR Code Printing, QR Code Printing on Open Form, Real Estate File Trading System"/>  

                                </div>                            
                            
                            </div>


<div class="p-3 demo1" >
    <div class="p-2">
    <h4 class="text-white">Property Management System:</h4>
</div>
</div>

<div class="container-xxl p-3" id="myGroup">
    <button class="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" href="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i class="bi bi-plus-circle phh1"></i>Digitization of Map / Floor Plan</button><br/>
 
          <div class=" p-3 collapse text-black"  id="lead" data-bs-parent="#myGroup">
            <li class="fontsm">Block / Floor Information</li>
            <li class="fontsm">Plot/Shop/Apartment Information</li>
            <li class="fontsm">Plot/Shop/Apartment Type/Nature & Category</li>
            <li class="fontsm">Plot/Shop/Apartment Status (Booked, Available/Reserve)</li>
            <li class="fontsm">Inquiry of (Available, Booked, Token & Reserved) Plot/Shop/Apartmen</li>
           
        </div> 


        <button class="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i class="bi bi-plus-circle phh1"></i>Client / File Registration Process</button> 
        <div class="p-3  collapse text-black" id="FollowUpLeads"  data-bs-parent="#myGroup">
        
            <li class="fontsm">Size Wise File Allocate to Client</li>

            <li class="fontsm">Personal Information of Client</li>
           
            <ul class="fontsm">
                <li>Name, Address, Contacts, (Scan Picture Attachment)</li>
                <li> Property Allocation at Booking Time (If Required)</li>
                </ul>
            <li class="fontsm">Booking’s Information of Client</li>
            <ul class="fontsm">
            <li>Total Price, Discount, Down Payment</li>
            <li>Installment & Other Payments Plan</li>
            </ul>

            <li class="fontsm">Payment Plan</li>
            <ul class="fontsm">
                <li>Facility of Fixed and Customized Plan</li>
                <li>Discount against Installment / Payments</li>
                </ul>
           </div>

        <button class="btttt bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i class="bi bi-plus-circle phh1"></i>File Information</button> 
        <div class="p-3  collapse text-black"    id="LeadsStatus" data-bs-parent="#myGroup">
        
            <li class="fontsm">Nominee Information</li>
            <ul class="fontsm">
                <li>ID Card, Name, Relationship, Address</li>
                </ul>
                <li class="fontsm">Misc File Information</li>
            <ul class="fontsm">
                <li>File Form No., Allotment No. Allotment Date</li>
                <li>Mail / Contact Subscription / Un-Subscriptions</li>
                </ul>
        </div>

        <button class="  btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i class="bi bi-plus-circle phh1"></i>Property Allocation</button> 
        <div class="p-3  collapse text-black"    id="ForwardLeads" data-bs-parent="#myGroup">
        
          <li class="fontsm">Allotment of Particular Plot/shop/Apartment</li>
          <li class="fontsm">Allocation Date, Allocation Authority</li>
        </div>
 
        <button class=" btttt  bounceInUp wow" data-wow-delay="0.5s" data-bs-toggle="collapse" href="#Dashboard" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Dashboard" > <i class="bi bi-plus-circle phh1"></i>Property Change</button> 
        <div class="p-3  collapse text-black"   id="Dashboard" data-bs-parent="#myGroup">
        
           <li class="fontsm">Extra- Facility to Change the Property Information</li>
          <li class="fontsm">Property Change History</li>
          <li class="fontsm">OLD Block/Floor No. Plot/Shop/Apartment No</li>
          <li class="fontsm">Plot/Shop/Apartment Status Booked, A</li>
          
          <li class="fontsm">Change Date, Change Officer</li> 
        </div>


     
        <button class="btttt  bounceInUp wow" data-wow-delay="0.6s" data-bs-toggle="collapse" href="#LeadsAnalysis" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsAnalysis" > <i class="bi bi-plus-circle phh1"></i>Receipt / Invoice (Against Installments/Payment)</button> 
        <div class="p-3  collapse text-black"    id="LeadsAnalysis" data-bs-parent="#myGroup">
        
            <li class="fontsm">Cash, Cheque, Receiving Date, Receiving Amount</li>
            <li class="fontsm">Cheque Complete Details (Drawee and Deposit Information)</li>
            <li class="fontsm">Auto Settlement of Received Amount in Due Payments</li>
            <li class="fontsm">Printing of Receipt/Invoice Copies </li>
        </div>

      
        <button class="btttt  bounceInUp wow" data-wow-delay="0.7s" data-bs-toggle="collapse" href="#LeadsReporting" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsReporting" > <i class="bi bi-plus-circle phh1"></i>Refund / Merge / Purchase Process</button> 
        <div class="p-3  collapse text-black"    id="LeadsReporting" data-bs-parent="#myGroup">
        
            <li class="fontsm">Refund Settlement Making</li>
            <li class="fontsm">Total Received, Non-Refundable, Deduction</li>
            <li class="fontsm">Net Refund Amount, Profit Calculation on Refund</li>
            <li class="fontsm">Refund History (Complete)</li>
  
        </div>

 
        <button class="btttt  bounceInUp wow" data-wow-delay="0.8s" data-bs-toggle="collapse" href="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i class="bi bi-plus-circle phh1"></i>Transfer of Property</button> 
        <div class="p-3  collapse text-black"    id="LeadsImportTrough" data-bs-parent="#myGroup">
            
            <li class="fontsm">Transfer Draft / Application Process</li>
            <li class="fontsm">Buyer Information update in System</li>
            <li class="fontsm"> Printing of Transfer Documentation</li>
            <ul class="fontsm">
                <li> (Allotment / Provisional Allotment Letter)</li>
                <li> NOC (No Objection Certificate)</li>
                <li> Undertaking Letter</li>
                <li>  Client update Ledger</li>
                <li> Transfer History</li>
            </ul>
        </div>
 
        <button class="btttt  bounceInUp wow" data-wow-delay="0.9s" data-bs-toggle="collapse" href="#Facebookintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Facebookintegration" > <i class="bi bi-plus-circle phh1"></i>File / Registration Cancellation</button> 
        <div class="p-3  collapse text-black"    id="Facebookintegration" data-bs-parent="#myGroup">
        
            <li class="fontsm">Cancellation due to Non Payments for a long time</li>
            <li class="fontsm">Cancellation Date, Cancellation Reason, Cancellation Officer</li>
            
            

        </div>
  
        <button class="btttt  bounceInUp wow" data-wow-delay="1s" data-bs-toggle="collapse" href="#SMSintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="SMSintegration" > <i class="bi bi-plus-circle phh1"></i>File / Registration Re-Activation</button> 
        <div class="p-3  collapse text-black"    id="SMSintegration" data-bs-parent="#myGroup">
        
            <li class="fontsm">Activation if Client wants to resume his File / Property</li>
            <li class="fontsm">Activation date, Activation Remarks, Activation Officer</li>


        </div>  

        <button class="btttt  bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#drp1" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp1" > <i class="bi bi-plus-circle phh1"></i> Inquiries</button> 
        <div class="p-3  collapse text-black"    id="drp1" data-bs-parent="#myGroup">
        
            <li class="fontsm">Inquiry of (Available, Booked, Token & Reserved) Plot/Shop/Apartment</li>
            <li class="fontsm">Payment Receipts Inquiry</li>
            <li class="fontsm">Client File / Registration Complete Inquiry</li>
        </div>  
      

        <button class="btttt  bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#drp3" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp3" > <i class="bi bi-plus-circle phh1"></i> Member Forms</button> 
        <div class="p-3  collapse text-black"    id="drp3" data-bs-parent="#myGroup">
        
            <li class="fontsm">Client Ledger / Form (Different Format)</li>
            
        </div>  
        <button class="btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#drp4" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp4" > <i class="bi bi-plus-circle phh1"></i> Invoice Printing (Different Format)</button>        
        <button class="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#drp2" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp2" > <i class="bi bi-plus-circle phh1"></i> Reports</button> 
        <div class="p-3  collapse text-black"    id="drp2" data-bs-parent="#myGroup">
            <li class="fontsm">Booking Reports (Date Wise)</li>
            <li class="fontsm"> Booking Report (Size Wise)</li>
            <li class="fontsm"> Property Reports</li>
            <li class="fontsm"> Refund Reports</li>
            <li class="fontsm"> Transfer Reports</li>
            <li class="fontsm"> Cancellation / Re-Activation Reports</li>
            <li class="fontsm"> Other Many Customized Reports on Demand Development</li>
            
            </div> 
            </div> 
        </div>
        <Demo/> 
        </div>


       
   

    

    </>
  )
}

export default Propertymanagementsystem