import React from "react";
import { Link } from "react-router-dom";
import "./Whatsapp.css"
const Whatsapp = ()=>{
return(
<>  
<Link  className="whats-app" to="https://api.whatsapp.com/send?phone=+923214812006&text=Hello%21%20QuaidSoft" target="_blank">
    <i className="fa fa-whatsapp my-float"></i>
</Link>
</>

);

}
export default Whatsapp;