import React from 'react'
import "./Demobar.css";
import { Link } from 'react-router-dom';
const Demobar = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
    <div className="p-4 cc demo1" >
    <div className="p-3">
    <h4 className='text-white'>Ask For Demo & Proposal</h4>
    <span className='text-white main-style'>If you are a developer or builder and are running your housing scheme or commercial projects and wish to automate your whole business flow, then please contact us for a software demonstration. Give us a chance to serve according to your business needs.</span>
</div>
<Link to="/contactus" onClick={scrollToTop} className="bttnn"> Ask Demo & Proposal</Link>
</div>
    </>
  )
}

export default Demobar