import React from 'react';
import "./Contactus.css";
import { Link } from 'react-router-dom';
const Contactus = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  return (
<>
<div className="container-xxl py-2">
    <div className=" text-center">
                <div className="row p-4">
                    <div className="col-lg-6 wow fadeInUp minh" data-wow-delay="0.1s" >
                        <div className="position-relative h-100">
                            <iframe className="mapp position-relative w-100 h-100"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.365811882959!2d74.34099181079604!3d31.51411134734438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x690a0188864e0af9%3A0x254b6898960ecf36!2sQuaid%20Soft%20%C2%AE%20%7C%20A%20Real%20Estate%20Software%20-%20Housing%20Scheme%20Software%20Company%20in%20Lahore%20Pakistan!5e0!3m2!1sen!2s!4v1701072931173!5m2!1sen!2s"
                            frameborder="0" allowfullscreen="" aria-hidden="false"
                            tabindex="0"></iframe>
                        
                        </div>
                    </div>

                    <div className="col-lg-6 wow fadeInUp p-2" data-wow-delay="0.5s">
                        <p className='ps'>If You Have Any Query, Please Contact Us</p>
                        <p className="mb-2 p11">Where Client satisfaction is our priority. Feel free to reach out to us for any inquiries regarding software proposals or detailed information about our Real Estate ERP and other modules. Our dedicated Sales Team is here to assist you, ensuring a seamless experience as you explore Real Estate Tailored & Customized Software solutions for your Project's business needs. 
Contact us today, and let's embark on a journey to elevate your software experience together.</p>
<p className='ps2'>Select to Contact</p>
    
<div className="w-100">
<Link to="https://api.whatsapp.com/send?phone=+923214812006&text=Hello%21%20QuaidSoft"  title="+923214812006" alt="+923214812006" target="_blank">
    <i className="bi bi-whatsapp soicn"></i>
    </Link>
    <Link  title="info@quaidsoft.com" alt="info@quaidsoft.com" onClick={scrollToTop} to="/contactus"><i className="fa fa-envelope soiw"></i></Link>
                      <Link  title="+923214812006" alt="+923214812006" to="tel:+923214812006" target="_blank"><i className="fa fa-phone soip"></i></Link>
                    </div>           
                    </div>
                </div>
            
        </div>

    </div>



</>
  )
}

export default Contactus