import {useEffect} from 'react'
import "./constructionmanagementsoftware.css"
import Demo from "../../Components/Parts/Demobar/Demobar.jsx"
import { Link } from 'react-router-dom'
const Constructionmanagementsoftware = () => {
    useEffect(() =>{
        document.title="top construction management software pakistan, best software, system, for housing society, property town real estate towns, project, billing scheme, cooperative society &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
   <>
    <div className=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">Construction Management System</h3>
        </div>
    </div>
    <div className="container-xxl py-6 p-4 courses" id="abouts">
                <div className="row">
                <div className="wow  bounceInUp mon1"data-wow-delay="0.2s">
                    <div className="w-100 p-2">
                        <h4>Construction Management System</h4>
                                                <p className="mb-2 acctextjus " >Is a platform that helps companies in processes like budget management, communication, decision-making, and job scheduling, to call some. Its goal is to create construction business processes lots easier through automation.</p>
                                                <p className="mb-2 acctextjus" ><strong className="text-black" >Construction Management System </strong> is a module specially developed to track and automate the whole business and operational flow of any kind of Construction <strong className="text-black" >(Housing Project & Commercial Projects)</strong> .</p>   
                                                   <p className="mb-2 acctextjus" >Construction management tools have significantly improved the development sector in terms of productivity, efficiency and company competitiveness. The features of <strong className="text-black" >construction management software</strong> typically help builders to automate their work and for documentation processes, which are essentially the aim of construction management software. During this section, we don’t just take a look at software features we also attempt to provide a picture of how construction management software works.  Many programs are integrated in such tools have seized tasks previously held by project managers, design engineers and designers. Construction firms at only once or another have used solutions in cost estimation, accounting, scheduling, and software systems. These are but a number of the benefits of construction management software. As vast because of these the features some solutions may offer essential functionalities that almost all companies need must be fulfilled.</p>
                                                   
                                                   <p className="mb-3 acctextjus" >Quaid Soft also provides <Link className="Link1" to="/accountsmanagementsoftware" > <b>Accounting software</b> </Link>,<Link className="Link1" to="/billing-management-software" > <b>Billing management system</b> </Link> , <Link className="Link1" to="/brand-sms" > <b>Brand Sms Software</b> </Link>,<Link className="Link1" to="/housing-scheme-software-in-pakistan" > <b>Inventory Management Software</b> </Link> ,<Link className="Link1" to="/construction-management-software" > <b>Construction Management Software</b> </Link> and Many others.</p>

                                                    <p className="mb-3 acctextjus " >Quaid soft gives 24/7 live support help our clients to achieve their milestones without any difficulty. Stay 24/7 connected with software through desktop. So whether you want to handle mid Real Estate operations or complex operations such as housing property operations, let our<strong className="text-black" > Real Estate Software </strong>automate your requirements and help you in your success mission in a managed way.</p>
                                                </div>   
                         
                            
                            </div>

<div className="p-3 demo1">
    <div className="p-2">
    <h4 className="text-white">Construction Management System includes:</h4>
</div>
</div>

<div className="container-xxl p-3" id="myGroup">
    <button className="btttt bounceInUp wow" data-wow-delay="0.1s"  data-bs-toggle="collapse" href="#lead" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="lead" > <i className="bi bi-plus-circle phh1 "></i>Category & Product Creation</button><br/>
          <div className=" p-3 collapse color-black"  id="lead" data-bs-parent="#myGroup">
            <li className="fontsm" >Categorization of Construction Items</li>
            <li className="fontsm" >Item Creation with Complete Specification</li>
            <ul className="fontsm" >
                <li>Product Measuring Unit</li>
                <li>Product Size & Specs</li>
                </ul>
                <li className="fontsm" >Item Purchase Rate for Costing</li>
                <li className="fontsm" >Minimum & Maximum Level (Store Alert)</li>
        </div> 


        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#FollowUpLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="FollowUpLeads" > <i className="bi bi-plus-circle phh1 "></i>Opening Stock</button> 
        <div className="p-3  collapse color-black" id="FollowUpLeads"  data-bs-parent="#myGroup">
            <li className="fontsm" >Store wise Opening Stock</li>
            <li className="fontsm" >Category wise opening Stock</li>
            <li className="fontsm" >Item Wise Stock</li>
           </div>

        <button className="btttt bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#LeadsStatus" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsStatus" > <i className="bi bi-plus-circle phh1 "></i>BOQ / Job Creation</button> 
        <div className="p-3  collapse color-black"  id="LeadsStatus" data-bs-parent="#myGroup">
            <li className="fontsm" >BOQ Name, Creation Date, Expected End Date</li>
            <li className="fontsm" >Material Quantity Item Wise & Specification</li>
            <li className="fontsm" >Estimate Rate & Estimate Value</li>
       
        </div>

        <button className="  btttt  bounceInUp wow" data-wow-delay="0.4s" data-bs-toggle="collapse" href="#ForwardLeads" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="ForwardLeads" > <i className="bi bi-plus-circle phh1 "></i>Supplier Information</button> 
        <div className="p-3  collapse color-black"  id="ForwardLeads" data-bs-parent="#myGroup">
            <li className="fontsm" >Supplier Name, Contact Person, NTN Number</li>
            <li className="fontsm" >Address, & Other Contacts</li>
        </div>
 
        <button className=" btttt  bounceInUp wow" data-wow-delay="0.5s" data-bs-toggle="collapse" href="#Dashboard" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Dashboard" > <i className="bi bi-plus-circle phh1 "></i>Purchase Demand Creation</button> 
        <div className="p-3 color-black  collapse"   id="Dashboard" data-bs-parent="#myGroup">
            <li className="fontsm" >Demand No. , Creation Date</li>
            <li className="fontsm" >Reference & Refer By</li>
            <li className="fontsm" >Demand Material List (Item Wise)</li>
        </div>


     
        <button className="btttt  bounceInUp wow" data-wow-delay="0.6s" data-bs-toggle="collapse" href="#LeadsAnalysis" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsAnalysis" > <i className="bi bi-plus-circle phh1 "></i>Purchase Orders (PO)</button> 
        <div className="p-3  collapse color-black"  id="LeadsAnalysis" data-bs-parent="#myGroup">
            <li className="fontsm" >(PO) to one Supplier</li>
            <li className="fontsm" >Item wise Multi (PO) to Different Supplier</li>
            <li className="fontsm" >Purchase Type (Local/Import)</li>
            <li className="fontsm" >Shipment Date, Reference (if any)</li>
            <li className="fontsm" >Order Quantity, Agreed Rate</li>
            <li className="fontsm" >(PO) Terms & Conditions</li>
            <li className="fontsm" >Last (PO) Terms & Condition with Specific Supplier</li>
            <li className="fontsm" >Purchase Order Printing (Internal Approval & For Supplier)</li>

            
           
        </div>

      
        <button className="btttt  bounceInUp wow" data-wow-delay="0.7s" data-bs-toggle="collapse" href="#LeadsReporting" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsReporting" > <i className="bi bi-plus-circle phh1 "></i>Purchase Order Receiving</button> 
        <div className="p-3  collapse color-black"  id="LeadsReporting" data-bs-parent="#myGroup">
        
            <li className="fontsm" >Good Receipts Note (GRN)</li>
            <li className="fontsm" >Multi GRN against One (PO)</li>
            <ul className="fontsm" >
                <li>Receive Date, Received Officer</li>
                <li>Vehicile Information, Supplier Ref No.</li>
                <li> Inspection, Recevied OR Rejection</li>
                </ul>
  
        </div>

 
        <button className="btttt  bounceInUp wow" data-wow-delay="0.8s" data-bs-toggle="collapse" href="#LeadsImportTrough" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="LeadsImportTrough" > <i className="bi bi-plus-circle phh1 "></i>Direct Purchase Update in Store</button> 

        <button className="btttt  bounceInUp wow" data-wow-delay="0.9s" data-bs-toggle="collapse" href="#Facebookintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="Facebookintegration" > <i className="bi bi-plus-circle phh1 "></i>Stock Requisition by Contractor</button> 
        <div className="p-3  collapse color-black"  id="Facebookintegration" data-bs-parent="#myGroup">
        
            <li className="fontsm" >Requisition No. , Creation Date</li>
            <li className="fontsm" >Reference & Refer By</li>
            <li className="fontsm" >Required Material List ( Item Wise)</li>
            
            

        </div>
  
        <button className="btttt  bounceInUp wow" data-wow-delay="1s" data-bs-toggle="collapse" href="#SMSintegration" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="SMSintegration" > <i className="bi bi-plus-circle phh1 "></i>Stock Issuance against Requisition</button> 

        <button className="btttt  bounceInUp wow" data-wow-delay="0.1s" data-bs-toggle="collapse" href="#drp1" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp1" > <i className="bi bi-plus-circle phh1 "></i>Stock Issuance Against BOQ /JOB</button> 
      
        <button className="btttt  bounceInUp wow" data-wow-delay="0.2s" data-bs-toggle="collapse" href="#drp2" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp2" > <i className="bi bi-plus-circle phh1 "></i>Stock Transfer to Other Store</button> 
        

        <button className="btttt  bounceInUp wow" data-wow-delay="0.3s" data-bs-toggle="collapse" href="#drp3" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp3" > <i className="bi bi-plus-circle phh1 "></i>Store Inquiry</button> 
        <div className="p-3  collapse color-black"  id="drp3" data-bs-parent="#myGroup">
        
            <li className="fontsm" >Category wise Stock</li>
            <li className="fontsm" >Item Wise Stock</li>
            <li className="fontsm" >Supplier Wise Purchase Inquiry</li>
            
        </div>  
        
    
        <button className="btttt  bounceInUp wow" data-wow-delay="0.5s" data-bs-toggle="collapse" href="#drp5" data-bs-parent="#myGroup" role="button" aria-expanded="false" aria-controls="drp5" > <i className="bi bi-plus-circle phh1 "></i>Reports</button> 
      </div>
      </div>
      <Demo/>
      </div>
      

   </>
  )
}

export default Constructionmanagementsoftware