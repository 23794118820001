import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import "./Support.css";

const Support = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Set isSubmitting to true to disable submit button
        emailjs
            .sendForm('service_mz0s14i', 'template_r6uwbfr', form.current, {
                publicKey: 'mgiJEQH-aUigd3KVJ',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    handleSubmit();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setIsSubmitting(false); // Reset isSubmitting to false on failure
                },
            );
    };
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate('/');
        alert('Your Query has been Received. We will contact you shortly. Thanks!');
        scrollToTop();
        setIsSubmitting(false); // Reset isSubmitting to false after successful submission
    };

    useEffect(() =>{
        document.title="Support &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);

  return (
    <>
    <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
    <div class="container text-center ">
        <h3 class="text-white animated slideInDown mb-4 mt-5">Support</h3>
    </div>
</div>
<div class="container-xxl courses">
 <div class="row p-4">
 <div class="www">
    <div class="text-center mt-5 p-2 suptextdiv  ">
    <p className='pppl'>If You Have Any Query, Please Contact Us</p>
                        <p class="mb-2 p112">Where client satisfaction is our priority. Feel free to reach out to us for any inquiries regarding software proposals or detailed information about our Real Estate ERP and other modules. Our dedicated Sales Team is here to assist you, ensuring a seamless experience as you explore Real Estate Tailored & Customized Software solutions for your Project's business needs. 
Contact us today, and let's embark on a journey to elevate your software experience together.</p>
</div>
<div class=" p-2  supformdiv">
        <form class="mt-2" ref={form} onSubmit={sendEmail}>

            <div class="formGroup">
                <lable class="label1">Name</lable>
                <input type="text" name="user_name" id="name" placeholder="Your Name" autocomplete="off"
                     required/>
            </div>

            <div class="formGroup">
            <lable class="label1">Email</lable>
                <input type="email" name="user_email"  placeholder="Email ID" autocomplete="off"
                    required/>
            </div>

            <div class="formGroup">
            <lable class="label1">Number</lable>
                <input type="text" pattern="^03\d{9}$" title="Please enter a valid phone number starting with '03'" name="user_number" placeholder="030012345.." autocomplete="off"
                     required/>
            </div>

            <div class="formGroup">
            <lable class="label1">Project Name</lable>
                <input type="text" name="user_project" placeholder="Project Name" autocomplete="off"
                     required/>
            </div>

            <div class="formGroup">
            <lable class="label1">Address</lable>
                <textarea type="text" name="user_address" placeholder="Address City and Location" autocomplete="off"
                     required></textarea>
            </div>


            <div class="formGroup">
            <lable class="label1">Explanation</lable>
                <textarea name="user_complaint" placeholder="Explanation...." required=""></textarea>
            </div>

            <div class="formGroup">
                <input class="bttnn" name="submit"type="submit" value="Send" disabled={isSubmitting}/>
            </div>
            
        </form>

</div>                       
</div>
</div></div>
   

</>

  )
}

export default Support