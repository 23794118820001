import React, { useEffect, useState, useRef } from 'react';
import './Client.css';

const Client = () => {
  const [count, setCount] = useState(0);
  const [builcount, builsetCount] = useState(1);
  const [houscount, houssetCount] = useState(1);
  const containerRef = useRef(null);

  useEffect(() => {
    let interval;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          interval = setInterval(() => {
            if (count < 600) {
              setCount((prevCount) => prevCount + 2);
            } else {
              clearInterval(interval); 
            }
          }, 1);
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      clearInterval(interval); // Clear interval when component unmounts
    };
  }, [count]);

  useEffect(() => {
    let interval;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          interval = setInterval(() => {
            if (builcount < 350) {
              builsetCount((prevCount) => prevCount + 1);
            } else {
              clearInterval(interval); // Stop the interval when count reaches 600
            }
          }, 1);
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      clearInterval(interval); // Clear interval when component unmounts
    };
  }, [builcount]);

  useEffect(() => {
    let interval;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          interval = setInterval(() => {
            if (houscount < 250) {
              houssetCount((prevCount) => prevCount + 1);
            } else {
              clearInterval(interval);
            }
          }, 1);
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      clearInterval(interval); // Clear interval when component unmounts
    };
  }, [houscount]);

  return (
    <>
<div ref={containerRef}  class="container-xxl">
    <div class="row">
    <div className='text-center text-white demo2 w-100  mb-4'>
            <h2>We Are The Leaders In Real Estate Industry</h2>
          </div>
        <div class="col-md-4 col-sm-6">
            <div class="counter ">
                <div class="counter-icon">
                    <i class="fa fa-users"></i>
                </div>
                <h3>Total Clients</h3>
                <span class="counter-value">{count} +</span>
            </div>
        </div>
        <div class="col-md-4 col-sm-6">
            <div class="counter blue">
                <div class="counter-icon">
                    <i class="fa fa-home"></i>
                </div>
                <h3>Housing Projects</h3>
                <span class="counter-value">{builcount} +</span>
            </div>
        </div>
        <div class="col-md-4 col-sm-6">
            <div class="counter orange">
                <div class="counter-icon">
                    <i class="fa fa-building"></i>
                </div>
                <h3>Building Projects</h3>
                <span class="counter-value">{houscount} +</span>
            </div>
        </div>
        
    </div>
</div>







      {/* <div ref={containerRef} className='container-xxl hero align-items-center'>
        <div className='row'>
          <div className='text-center w-100'>
            <h2>We Are The Leaders In Industry</h2>
          </div>
          <div>
            <div className='row btncount d-flex justify-content-between'>
              <div className='col '>
                <div className='stats-item text-center w-100'>
                  <p>Total Clients</p>
                  <span>{count} +</span>
                </div>
              </div>

              <div className=' col '>
                <div className='stats-item text-center w-100 '>
                  <p>Housing Projects</p>
                  <span>{builcount} +</span>
                </div>
              </div>

              <div className='col'>
                <div className='stats-item text-center w-100 '>
                  <p>Building Projects</p>
                  <span>{houscount} +</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Client;
