import {useEffect}from 'react'
import "./Blog.css"
const Blog = () => {
    useEffect(() =>{
        document.title="Blog|Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>
      <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center ">
            <h3 class="text-white animated slideInDown mb-4 mt-5">Blog</h3>
        </div>
    </div>
    <div class="container-xxl py-6 p-4" id="abouts">


<div class="row g-4 justify-content-center">
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <div class="courses-item d-flex flex-column bg-light overflow-hidden h-100">
            <div class="text-center p-2 pt-0">
                <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">ERP Management System</div>
                <p>Our Property Management System in Pakistan Real Estate industry is designed to also optimize sales management processes by improving customer service</p>
                <ol class="breadcrumb justify-content-center mb-0">
                </ol>
            </div>
            <div class="position-relative mt-auto">
                <iframe class="vierr"  src="https://www.youtube.com/embed/V_U7rGMI7NA?si=3nk3hD23BghWWNm9" title="Real Estate CRM Lead Management System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
        <div class="courses-item d-flex flex-column bg-light overflow-hidden h-100">
            <div class="text-center p-2 pt-0">
                <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">CRM Management System</div>

                <p>A Real Estate CRM-Leads Management System/CRM Management System is a powerful software solution designed specifically for real estate professionals to effectively manage leads, track customer interactions, and streamline sales processes.</p>
            </div>
            <div class="position-relative mt-auto">
                <iframe class="vierr"  src="https://www.youtube.com/embed/KEAO_dtLXjY" title="Real Estate CRM Lead Management System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
        <div class="courses-item d-flex flex-column bg-light overflow-hidden h-100">
            <div class="text-center p-2 pt-0">
                <div class="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">Sales Management System</div>
                <p> An automating software for the sales process to organize their entire customer accounts. Quaid Soft offers the best possible solution to Real Estate/Property Management Software in Pakistan. Quaid Soft provides services all over Pakistan including major cities Lahore, Islamabad.</p>
                <ol class="breadcrumb justify-content-center mb-0">
                </ol>
            </div>
            <div class="position-relative mt-auto">
                <iframe class="vierr"  src="https://www.youtube.com/embed/4xQrJBy4x4M?si=LD6V9YEwMqUGK1W1" title="Real Estate CRM Lead Management System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                
            </div>
        </div>
    </div>
</div>
</div>
    
    </>
  )
}

export default Blog