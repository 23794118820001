import React,{useRef,useEffect ,useState} from 'react'
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import "./Bookinghosting.css"
const Bookinghosting = () => {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetchCountries();
  }, []);
  const fetchCountries = async () => {
    try {
      const response = await fetch('https://restcountries.com/v2/all');
      const data = await response.json();
      setCountries(data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const form = useRef();

  const sendEmail = (e) => {
      e.preventDefault();
      setIsSubmitting(true); // Set isSubmitting to true to disable submit button
      emailjs
          .sendForm('service_mz0s14i', 'template_4bbubd2', form.current, {
              publicKey: 'mgiJEQH-aUigd3KVJ',
          })
          .then(
              () => {
                  console.log('SUCCESS!');
                  handleSubmit();
              },
              (error) => {
                  console.log('FAILED...', error.text);
                  setIsSubmitting(false); // Reset isSubmitting to false on failure
              },
          );
  };
  const navigate = useNavigate();

  const handleSubmit = () => {
      navigate('/');
      alert('Your Query has been Received. We will contact you shortly. Thanks!');
      scrollToTop();
      setIsSubmitting(false);
  };
  useEffect(() =>{
      document.title="Domain and Hosting &#8211; Quaid Soft | ERP Software Company In Pakistan";
  },[]);

  

  return (
   <>
    <div class=" page-header py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center ">
            <h3 class="text-white animated slideInDown mb-4 mt-5">Booking Domain and Hostig</h3>
        </div>
    </div>
   <div class="container-xxl courses ">
<div class="row p-4">
 <div class="www">
    <div class="text-center mt-5 bootextdib  p-4">
    <p className='ppp'>Welcome to our Domain and Hosting Reservation Form</p>
                        <p class="mb-2 boodom ">Welcome to our Domain and Hosting Reservation Form! We're excited to help you establish your online presence. To secure your domain and hosting, please fill in the required details. Provide your full name, email address, and phone number. Indicate your preferred domain name, top-level domain, and any alternative choices. Select your desired hosting package and duration. Optionally, choose additional services like an SSL certificate or website builder. Share your billing information, including the billing address and preferred payment method. Don't forget to review and agree to the terms and conditions. Click 'Submit' when you're ready. We appreciate your trust in our services and will contact you soon to finalize the reservation.</p>
</div>
<div class=" bookformdiv p-2">
<form class="mt-2" ref={form} onSubmit={sendEmail}>
                <h3>Domain</h3>  
                <div className='www'>   
            <div class="formGroup" >
                <label for="name">Domain Name <span>(optional)</span></label>
                <input type="text" name="contact_name" id="name" placeholder="Domain Name" autocomplete="off"
                    />
            </div>

            <div class="formGroup">
                <label for="email">Email ID</label>
                <input type="email" name="contact_email" id="email" placeholder="Email ID" autocomplete="off"
                     required/>
            </div>
            </div>
            <div class="formGroup">
                <label for="subject">Phone No</label>
                <input type="text" name="contact_number" id="subject" placeholder="Phone No" autocomplete="off"
                     required/>
            </div>

              
            <h3>Hosting</h3>   
                <div class="formGroup">
                    <label for="plan">Select Plan</label>
                   <select class="opti" name="contact_service"  required>
    <option value="" class="opti" selected disabled hidden>Select Plan</option>
    <option value="Basic" class="opti" >Basic Plan</option>
    <option value="Business" class="opti"  >Business Plan</option>
    <option value="Premium" class="opti"  >Premium Plan</option>

</select>
                </div>
 <div class="www">
                <div class="formGroup w-100">
                    <label for="country">Select Country</label>
                    <select name='contact_project' required>
        <option value="" disabled hidden>Select Country</option>
        {countries.map(country => (
          <option key={country.alpha2Code} value={country.name}>{country.name}</option>
        ))}
      </select>
</div>

                <div class="formGroup w-100" >
                    <label for="city">City</label>
                    <input type="text" name="contact_city" id="subject" placeholder="City" autocomplete="off"
                     required=""/>
            
                </div>
                
            </div>
            <div class="formGroup">
                                    <label class="label1">Message</label>
                                    <textarea name="message" placeholder="Message" required></textarea>
                                </div>

            <div class="formGroup">
            <input class="bttnn" name="submit" type="submit" value="Send" disabled={isSubmitting} />            </div>

      
        </form>
</div>
</div>
</div>
</div>

   
   </>
  )
}

export default Bookinghosting