import {useEffect} from 'react'
import "./Cctvcameras.css";
import Demo from "../../Components/Parts/Demobar/Demobar1.jsx"
const Cctvcameras = () => {
    useEffect(() =>{
        document.title="CCTV cameras &#8211; Quaid Soft | ERP Software Company In Pakistan";
    },[]);
  return (
    <>    
    <div className=" page-header  py-2 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center ">
            <h3 className="text-white animated slideInDown mb-4 mt-5">CCTV CAMERAS</h3>
        </div>
    </div> 

    <div className="container-xxl courses">
    <div className="row p-4 ">
    <div className="CCTdisplayf">
        <div className=" w-100 p-3">
            <p className="mb-2 ccttextjus">CCTV security systems stand out as a paramount investment for safeguarding your premises, proving their worth immediately upon installation. Offering a comprehensive view of your surroundings, these systems provide real-time recording capabilities that serve as an invaluable deterrent against potential threats. The ability for remote online access, granted to both owners and managers, empowers them with constant surveillance and control.</p>  
            <p className="mb-4 ccttextjus">This advanced technology significantly diminishes the risk of various costly incidents, including burglary, fire, and vandalism. The all-encompassing coverage ensures that no corner of your property is left unmonitored, bolstering overall security. In the event of an unfortunate incident, the recorded footage becomes a crucial tool for investigation and evidence collection.</p>                                                                                       
            </div>                  
       <div className="shootharddiv"> 
       <img className="w-100 ing1 h-100" src={process.env.PUBLIC_URL + '/Images/Products/cctv-cameras-housing-societies.jpg'} alt="cctv-cameras-housing-societies"/>  
       </div>           
    </div>
    </div>
<div className='row p-4'>
<div className='cctvdisf w-100'>
<p> <h3 className="ckb">CCTV Security Systems</h3> CCTV security systems have emerged as a pivotal investment for safeguarding properties, proving their immediate value upon installation. These systems offer a comprehensive view of the surroundings and boast real-time recording capabilities, serving as a powerful deterrent against potential threats. The convenience of remote online access further empowers property owners and managers, providing constant surveillance and control.</p>
</div>
<div className='cctvdisf w-100'>
<p> <h3 className="ckb">Deterrence & Prevention of Incidents</h3> The advanced technology embedded in CCTV systems significantly diminishes the risk of various costly incidents, such as burglary, fire, and vandalism. The mere presence of visible cameras acts as a deterrent, discouraging potential intruders and vandals from targeting the premises. This proactive approach to security helps create a safer environment and minimizes the likelihood of criminal activities.</p>
</div>
<div className='cctvdisf w-100'>
<p> <h3 className="ckb">Coverage Enhancing Security</h3> The all-encompassing coverage offered by CCTV systems ensures that no corner of a property is left unmonitored. This comprehensive surveillance plays a crucial role in bolstering overall security. By eliminating blind spots, these systems provide a level of protection that traditional security measures may lack, offering peace of mind to property owners and occupants.</p>
</div>
<div className='cctvdisf w-100'>
<p> <h3 className="ckb">Remote Access and Control</h3> One of the key features of CCTV security systems is the ability for remote online access. Owners and managers can monitor their premises from anywhere, at any time, through internet-connected devices. This level of accessibility not only enhances convenience but also allows for immediate response to potential security concerns, making it a valuable tool for proactive security management.</p>
</div>
<div className='cctvdisf w-100'>
<p> <h3 className="ckb">Mitigation & Incident Resolution</h3> In the unfortunate event of an incident, the recorded footage from CCTV systems becomes a crucial tool for investigation. This documentation aids law enforcement in identifying perpetrators, understanding the sequence of events, and ultimately facilitating a quicker resolution to the incident. The presence of CCTV footage can be instrumental in legal proceedings and insurance claims.</p>
</div>
<div className='cctvdisf w-100'>
<p> <h3 className="ckb">Beyond immediate security benefits</h3> CCTV systems offer a long-term return on investment. The protection they provide can lead to reduced insurance premiums, decreased likelihood of legal liabilities. The peace of mind afforded to property owners and occupants is immeasurable, making CCTV security systems a paramount and enduring investment in the safety.</p>
</div>

</div>
<Demo/>

      </div>   
  
           
     </>
          
  )
}

export default Cctvcameras