import React from "react";
import { Link } from "react-router-dom";
import "./Tober.css"
const Topnav = ()=>{
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
return(
<>  
<Link  class="arrow" onClick={scrollToTop}>
    <i class="bi bi-arrow-up-circle-fill my-float1"></i>
</Link>
</>

);

}
export default Topnav;